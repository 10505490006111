import { Popover } from 'react-tiny-popover'
import { useState } from 'react'
import TableFilterBlock from './TableFilterBlock'
import {
  TableFilterSegmentProps,
  formatConditionValue,
  getComparatorChipString,
} from './TableFilterCollection'
import { CustomFilter } from './PipelineTable'
import {
  Box,
  Center,
  Popover as ChakraPopover,
  Flex,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import React from 'react'
import { FaCircle } from 'react-icons/fa6'
import { highlightIconColors } from '../../lib/colors'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { BiHighlight } from 'react-icons/bi'
import { isEqual } from 'lodash'

type AddHighlightProps = {
  fields: any[]
  highlights: Array<CustomFilter & { color?: string; background?: string }>
  setHighlights: Function
  users: any[]
}
const AddHighlight = (props: AddHighlightProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <Popover
      {...{
        isOpen: isPopoverOpen,
        padding: 10,
        containerClassName: 'z-10',
        transformMode: 'relative',
        positions: ['left'],
        clickOutsideCapture: true,
        onClickOutside: e => {
          e.preventDefault()
          setIsPopoverOpen(false)
        },
        content: (
          <TableFilterBlock
            {...{
              teams: [],
              user: undefined,
              users: props.users,
              fields: props.fields,
              filters: props.highlights,
              setFilters: props.setHighlights,
              objectName: 'account',
              onSubmit: () => setIsPopoverOpen(false),
            }}
          />
        ),
      }}
    >
      <Box
        paddingLeft='14px'
        paddingRight='14px'
        fontSize='12px'
        marginTop='1px'
        border='1px solid #e4e4e7'
        borderRadius='md'
        _hover={{
          cursor: 'pointer',
        }}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        Add
      </Box>
    </Popover>
  )
}

type Props = {
  fields: any[]
  highlights: Array<CustomFilter & { color?: string; background?: string }>
  setHighlights: Function
  users: any[]
}

export const TooltipFilter = (props: TableFilterSegmentProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const FilterString = props.filter.conditions.map((condition, index) => {
    const comparatorString = getComparatorChipString(
      condition.comparator,
      condition.value,
    )
    let valueString = ''

    if (!['last_n_days', 'last_n_months', 'next_n_days', 'next_n_months', 'last_n_quarters', 'next_n_quarters', 'more_than_n_days_ago', 'less_than_n_days_ago'].includes(condition.comparator)) {
      if (
        [
          'greater_than_percent',
          'less_than_percent',
          'today',
          'not_today',
          'before_today',
          'after_today',
          'this_week',
          'this_month',
          'this_quarter',
          'next_month',
          'next_quarter',
        ].includes(condition.comparator)
      ) {
        valueString =
          props.fields?.find(
            field => field.name === condition.comparativeFieldName,
          )?.label || condition.comparativeFieldName
      } else if (props.filter.key === 'RecordTypeId') {
        valueString =
          props.fields
            ?.find(field => field.name === 'RecordTypeId')
            ?.picklist_values?.find(
              (picklistValue: any) => picklistValue.value === condition.value,
            )?.label || formatConditionValue(condition.value)
      } else {
        valueString = formatConditionValue(condition.value)
      }
    }

    const operatorString =
      index < props.filter.conditions.length - 1
        ? ` ${props.filter.operator} `
        : ''
    return (
      <React.Fragment key={index}>
        {comparatorString}{' '}
        <Text as='span' fontWeight='400'>
          {valueString}
        </Text>
        {operatorString}
      </React.Fragment>
    )
  })
  // .join(` ${props.filter.label} `)

  return (
    <Flex alignItems='center' textColor='black' wrap='wrap'>
      <Popover
        {...{
          isOpen: isPopoverOpen,
          padding: 10,
          containerClassName: 'z-10',
          transformMode: 'relative',
          positions: ['bottom'],
          clickOutsideCapture: true,
          onClickOutside: e => {
            e.preventDefault()

            setIsPopoverOpen(false)
          },
          content: (
            <TableFilterBlock
              {...{
                user: props.user,
                users: props.users,
                teams: props.teams,
                fields: props.fields,
                filters: props.filters,
                setFilters: props.setFilters,
                selectedFilter: props.filter,
                selectedFilterIndex: props.filterIndex,
                objectName: 'account',
                onSubmit: () => setIsPopoverOpen(false),
              }}
            />
          ),
        }}
      >
        <Box onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
          <Text fontWeight='350'>
            {props.filter.label} {FilterString}
          </Text>
        </Box>
      </Popover>
    </Flex>
  )
}

export const TooltipContent = ({
  data,
  fields,
}: {
  data: Array<{ filter: CustomFilter; color: string }>
  fields: Array<any>
}) => {
  return (
    <Box>
      {data.map((item, index) => (
        <Flex
          key={index}
          alignItems='center'
          pb={index !== data.length - 1 ? 1 : 0}
        >
          <Box
            as={FaCircle}
            minH='8px'
            minW='8px'
            boxSize='8px' // Adjust the size of the circles
            color={item.color}
            mr={2}
          />
          <TooltipFilter
            filterIndex={index}
            user={undefined}
            teams={[]}
            users={[]}
            filters={data.map((d: any) => d.filter)}
            setFilters={() => {}}
            filter={item.filter}
            fields={fields}
          />
        </Flex>
      ))}
    </Box>
  )
}

export const FilterMatchHighlight = React.memo(
  ({
    data,
    fields,
    shouldDisable,
  }: {
    data: Array<{ filter: CustomFilter; color: string }>
    fields: Array<any>
    shouldDisable?: boolean
  }) => {
    return (
      <Tooltip
        label={<TooltipContent data={data} fields={fields} />}
        hasArrow
        shouldWrapChildren
        pointerEvents='all'
        background='white'
        closeDelay={350}
        isDisabled={shouldDisable}
      >
        <Flex alignItems='center'>
          <Flex height='10px' position='relative' ml={1} alignItems='center'>
            {data.slice(0, 3).map((item, index) => (
              <Box
                key={index}
                as={FaCircle}
                position='absolute'
                left={`${index * 4}px`} // Adjust this value to control the overlap
                boxSize='8px' // Adjust the size of the circles
                color={item.color}
                borderRadius='50%'
                border='0.2px solid white'
              />
            ))}
          </Flex>
          <Box pl={4} fontSize='11px'>
            {data.length > 3 ? `+${data.length - 3}` : ''}
          </Box>
        </Flex>
      </Tooltip>
    )
  },
  (prevProps, nextProps) => {
    if (!isEqual(prevProps.data, nextProps.data)) {
      return false
    }
    if (!isEqual(prevProps.fields, nextProps.fields)) {
      return false
    }
    return true
  },
)

FilterMatchHighlight.displayName = 'FilterMatchHighlight'

export default function HighlightsMenu(props: Props) {
  return (
    <>
      <ChakraPopover
        placement='bottom-end'
        closeOnBlur={false}
        onClose={() => {}}
      >
        {({ onClose }) => {
          return (
            <>
              <PopoverTrigger>
                <Box
                  display='flex'
                  alignItems='center'
                  borderRadius='md'
                  fontSize='xs'
                  bgColor='white'
                  border='1px'
                  borderColor='#e4e4e7'
                  px='3'
                  py='1'
                  fontWeight='medium'
                  cursor='pointer'
                  lineHeight='tight'
                  whiteSpace='nowrap'
                  _hover={{ bgColor: '#fafafa' }}
                  {...props} // Allows you to pass additional props to the Box component
                >
                  <BiHighlight size={16} />
                  <Text style={{ paddingLeft: '8px', fontWeight: 500 }}>
                    Highlights
                  </Text>
                </Box>
              </PopoverTrigger>
              <PopoverContent w='350px' minH='100px' borderColor='#e4e4e7'>
                <Box p={2}>
                  <Flex
                    w='full'
                    justifyContent='space-between'
                    alignItems='center'
                    px={1}
                  >
                    <Text></Text>
                    <Flex alignItems='center'>
                      <AddHighlight {...props} />

                      <Box
                        pl={1}
                        _hover={{
                          cursor: 'pointer',
                        }}
                      >
                        <XMarkIcon
                          height={16}
                          width={16}
                          onClick={() => {
                            onClose()
                          }}
                        />
                      </Box>
                    </Flex>
                  </Flex>

                  <Flex pt={2} gap={2} direction='column' px={1}>
                    {props.highlights.length ? (
                      props.highlights.map((highlight, index) => {
                        return (
                          <Flex
                            key={index}
                            alignItems='center'
                            justifyContent='space-between'
                            pb={index !== props.highlights.length - 1 ? 1 : 0}
                          >
                            <Flex alignItems='center'>
                              <Box
                                as={FaCircle}
                                minH='8px'
                                minW='8px'
                                boxSize='8px' // Adjust the size of the circles
                                color={
                                  highlightIconColors[
                                    index % highlightIconColors.length
                                  ]
                                }
                                mr={2}
                              />
                              <TooltipFilter
                                user={undefined}
                                teams={[]}
                                users={[]}
                                filters={props.highlights}
                                setFilters={props.setHighlights}
                                filterIndex={index}
                                filter={highlight}
                                fields={props.fields}
                              />
                            </Flex>

                            <Box _hover={{ cursor: 'pointer' }}>
                              <XCircleIcon
                                height={16}
                                width={16}
                                onClick={() => {
                                  props.setHighlights(
                                    props.highlights.filter(
                                      existing => existing.id !== highlight.id,
                                    ),
                                  )
                                }}
                              />
                            </Box>
                          </Flex>
                        )
                      })
                    ) : (
                      <Center h='35px'>
                        <Box fontWeight='500' fontSize='14px' color='#858585'>
                          No highlights added
                        </Box>
                      </Center>
                    )}
                  </Flex>
                </Box>
              </PopoverContent>
            </>
          )
        }}
      </ChakraPopover>
    </>
  )
}
