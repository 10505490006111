import {
  Flex,
  Button,
  Checkbox,
  Text,
  Box,
  Center,
  Spinner,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  VStack,
} from '@chakra-ui/react'
import { useState, useEffect, useContext } from 'react'
import { Command } from 'cmdk'

import { StyledCommandItem } from '.'
import { StateStore } from '../Store'
import { Option } from '../Nodes/v2/FormSections/UpdateRecordSection'

type Props = {
  page: string
  selectedField: { field_name: string; object_name: string }
  onConfirm: () => void
}
const picklistValues = [
  'Prospecting',
  'Qualification',
  'Needs Analysis',
  'Value Proposition',
  'Id. Decision Makers',
  'Perception Analysis',
  'Proposal/Price Quote',
  'Negotiation/Review',
  'Closed Won',
  'Closed Lost',
]

const getRandomPicklistValue = () => {
  return picklistValues[Math.floor(Math.random() * picklistValues.length)]
}

export default function PicklistReferences(props: Props) {
  const { page, selectedField, onConfirm } = props

  const { dispatch } = useContext(StateStore)

  const [isLoading, setIsLoading] = useState(true)

  const [fieldReferences, setFieldReferences] = useState<
    Array<{
      groupItem: string
      items: Array<{
        groupItem: string
        items: Array<{ api_name: string; label: string }>
      }>
    }>
  >([])
  const [picklistOptions, setPicklistOptions] = useState<Array<Option>>([])

  const [selectedType, setSelectedType] = useState('')

  const [selectedFieldReplacements, setSelectedFieldReplacements] = useState<{
    [key: string]: Array<{ api_name: string; label: string }>
  }>({})

  useEffect(() => {
    if (!!selectedField) {
      setIsLoading(true)

      setTimeout(() => {
        const FIELD_REFERENCES = [
          {
            groupItem: 'Qualification',
            items: [
              {
                groupItem: 'Validation Rule',
                items: [
                  {
                    api_name: 'Opportunity.Close_Date_within_30_Days',
                    label: 'Close Date Within 30 Days',
                  },
                ],
              },
              {
                groupItem: 'Formula',
                items: [
                  {
                    api_name: 'Opportunity.Record_Type_New_Business',
                    label: 'Record Type New Business',
                  },
                ],
              },
            ],
          },
          {
            groupItem: 'Value Proposition',
            items: [
              {
                groupItem: 'Validation Rule',
                items: [
                  {
                    api_name: 'Opportunity.Close_Date_within_30_Days',
                    label: 'Close Date Within 30 Days',
                  },
                ],
              },
              {
                groupItem: 'Formula',
                items: [
                  {
                    api_name: 'Opportunity.Record_Type_New_Business',
                    label: 'Record Type New Business',
                  },
                ],
              },
            ],
          },
          {
            groupItem: 'Proposal/Price Quote',
            items: [
              {
                groupItem: 'Formula',
                items: [
                  {
                    api_name:
                      'Opportunity.Renewal_Record_Delivery_Status_Check',
                    label: 'Expected Calculated Amount',
                  },
                ],
              },
            ],
          },
          {
            groupItem: 'Closed Won',
            items: [
              {
                groupItem: 'Validation Rule',
                items: [
                  {
                    api_name: 'Opportunity.Opportunity_Type_Cannot_Be_Blank',
                    label: 'Amount Defined When Closed Won',
                  },
                ],
              },
            ],
          },
          {
            groupItem: 'Closed Lost',
            items: [
              {
                groupItem: 'Validation Rule',
                items: [
                  {
                    api_name: 'Opportunity.Opportunity_Type_Cannot_Be_Blank',
                    label: 'Close Lost Reason Filled Out',
                  },
                ],
              },
            ],
          },
        ]

        setPicklistOptions(
          FIELD_REFERENCES.map(ref => {
            return { label: ref.groupItem, value: ref.groupItem }
          }),
        )

        setSelectedType(FIELD_REFERENCES[0].groupItem)

        setFieldReferences(FIELD_REFERENCES)
        setIsLoading(false)
      }, 1000)
    } else {
      setFieldReferences([])
    }
  }, [selectedField])

  if (page === 'Picklist') {
    return (
      <div
        style={{
          paddingBottom: '10px',
        }}
      >
        {isLoading ? (
          <Center h='200px' w='full'>
            <Spinner />
          </Center>
        ) : (
          <Box minH='200px' maxHeight='600px' overflowY='scroll'>
            <Flex w='full' alignItems='center' pl={5}>
              <Text fontSize='13px' pr={1}>
                Picklist value:
              </Text>
              <Popover placement='bottom-start'>
                <PopoverTrigger>
                  <button
                    //   style={}
                    className='bg-white text-[12px] rounded-md px-2 py-1 leading-tight border-[#e4e4e7] border hover:bg-[#fafafa] whitespace-nowrap'
                  >
                    {selectedType}
                  </button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    <RadioGroup
                      colorScheme='purple'
                      fontSize='12px'
                      onChange={value => {
                        setSelectedType(value)
                      }}
                      value={selectedType}
                    >
                      <VStack alignItems='flex-start'>
                        {picklistOptions.map(option => {
                          return (
                            <Radio
                              key={option.value}
                              value={option.value}
                              fontSize='13px'
                              style={{
                                fontSize: '13px',
                              }}
                            >
                              {option.value}
                            </Radio>
                          )
                        })}
                      </VStack>
                    </RadioGroup>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>

            {fieldReferences
              .find(ref => ref.groupItem === selectedType)
              ?.items.map(fieldGroup => {
                return (
                  <Command.Group
                    key={fieldGroup.groupItem}
                    style={{ paddingTop: '8px', paddingBottom: '8px' }}
                  >
                    <Flex
                      w='full'
                      justifyContent='space-between'
                      alignItems='center'
                      pb={2}
                    >
                      <Text
                        fontSize='15px'
                        color='gray.900'
                        pl={5}
                        fontWeight={500}
                      >
                        {fieldGroup.groupItem}
                      </Text>
                      <Flex mr='21.5px'>
                        <Text
                          fontSize='15px'
                          color='gray.900'
                          fontWeight={300}
                          pr={3}
                        >
                          Replace all
                        </Text>
                        <Checkbox
                          colorScheme='purple'
                          isChecked={
                            selectedFieldReplacements[fieldGroup.groupItem]
                              ?.length === fieldGroup.items.length
                          }
                          onChange={e => {
                            if (e.target.checked) {
                              setSelectedFieldReplacements(prev => {
                                return {
                                  ...prev,
                                  [fieldGroup.groupItem]: fieldGroup.items,
                                }
                              })
                            } else {
                              setSelectedFieldReplacements(prev => {
                                return {
                                  ...prev,
                                  [fieldGroup.groupItem]: [],
                                }
                              })
                            }
                          }}
                          sx={{
                            '.chakra-checkbox__control': {
                              height: '18px',
                              width: '18px',
                              borderRadius: '3px',
                              borderColor: 'black', // Color of the border
                              _checked: {
                                color: 'white', // Color of the check icon when checked
                                border: 'none',
                                svg: {
                                  transform: 'scale(1.3)', // Scale up the checkmark
                                },
                              },
                              _hover: {
                                borderColor: 'black', // Border color on hover
                              },
                              _focus: {
                                boxShadow: 'none', // Remove the focus outline
                              },
                            },
                          }}
                        />
                      </Flex>
                    </Flex>
                    {fieldGroup.items.map((item, index) => {
                      return (
                        <StyledCommandItem
                          key={`${fieldGroup.groupItem}-item-${index}`}
                        >
                          <Flex
                            direction={'row'}
                            grow={1}
                            width='100%'
                            alignItems={'center'}
                            justifyContent='space-between'
                            paddingX={1}
                            paddingY={2}
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()

                              setSelectedFieldReplacements(prev => {
                                const existingSelected =
                                  prev[fieldGroup.groupItem]
                                return {
                                  ...prev,
                                  [fieldGroup.groupItem]:
                                    existingSelected?.includes(item)
                                      ? existingSelected?.filter(
                                          existingItem => existingItem !== item,
                                        )
                                      : [...(existingSelected ?? []), item],
                                }
                              })
                            }}
                          >
                            <Flex direction={'row'} alignItems='center' gap={4}>
                              <Flex direction={'column'}>
                                <Text fontSize={'14px'} fontWeight={'400'}>
                                  {item.label}
                                </Text>
                              </Flex>
                            </Flex>
                            <Checkbox
                              colorScheme='purple'
                              isChecked={selectedFieldReplacements[
                                fieldGroup.groupItem
                              ]?.includes(item)}
                              onChange={e => {
                                if (e.target.checked) {
                                  setSelectedFieldReplacements(prev => {
                                    return {
                                      ...prev,
                                      [fieldGroup.groupItem]: [
                                        ...(prev[fieldGroup.groupItem] ?? []),
                                        item,
                                      ],
                                    }
                                  })
                                } else {
                                  setSelectedFieldReplacements(prev => {
                                    return {
                                      ...prev,
                                      [fieldGroup.groupItem]: prev[
                                        fieldGroup.groupItem
                                      ].filter(
                                        existingItem => existingItem !== item,
                                      ),
                                    }
                                  })
                                }
                              }}
                              sx={{
                                '.chakra-checkbox__control': {
                                  height: '18px',
                                  width: '18px',
                                  borderRadius: '3px',
                                  borderColor: 'black', // Color of the border
                                  _checked: {
                                    color: 'white',
                                    border: 'none', // Color of the check icon when checked
                                    svg: {
                                      transform: 'scale(1.3)', // Scale up the checkmark
                                    },
                                  },
                                  _hover: {
                                    borderColor: 'black', // Border color on hover
                                  },
                                  _focus: {
                                    boxShadow: 'none', // Remove the focus outline
                                  },
                                },
                              }}
                            />
                          </Flex>
                        </StyledCommandItem>
                      )
                    })}
                  </Command.Group>
                )
              })}
          </Box>
        )}

        <Flex justifyContent='flex-end' pr={2}>
          {!isLoading && (
            <Button
              size='sm'
              variant='primaryCTA'
              isDisabled={
                !Object.values(selectedFieldReplacements).some(
                  arr => arr.length > 0,
                )
              }
              {...(!Object.values(selectedFieldReplacements).some(
                arr => arr.length > 0,
              ) && {
                _hover: {
                  cursor: 'not-allowed',
                  opacity: 0.4,
                },
              })}
              onClick={() => {
                dispatch({
                  type: 'TOGGLE_MODAL',
                  payload: {
                    key: 'replacePicklist',
                    data: {
                      selectedField: selectedField,
                      selectedType: selectedType,
                      references: selectedFieldReplacements,
                    },
                  },
                })
                onConfirm()
              }}
            >
              Replace
            </Button>
          )}
        </Flex>
      </div>
    )
  }

  return <></>
}
