import { AnimatePresence, MotionProps, motion } from 'framer-motion'
import { useContext, useEffect, useRef } from 'react'
import { StateStore } from '../Store'
import { get } from 'lodash'

const CommandWrapper = (
  props: MotionProps & {
    children: React.ReactNode
  },
) => {
  const { state, dispatch } = useContext(StateStore)

  const commandMenuOpen =
    (get(state, 'modal.key', '') as string) === 'commandMenu'

  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      dispatch({
        type: 'SET_COMMAND_WRAPPER_REF',
        payload: {
          ref,
        },
      })
    }
  }, [ref, dispatch])

  return (
    <AnimatePresence
      {...{
        initial: false,
      }}
    >
      <motion.div
        initial={{ scale: 0.98 }}
        ref={ref}
        animate={{
          scale: commandMenuOpen ? 1 : 0.98,
        }}
        exit={{ scale: 0.98 }}
        transition={{ duration: 0.5 }}
        style={{
          height: '100vh',
          position: 'absolute',
          width: '100%',
          zIndex: commandMenuOpen ? '20' : '0',
        }}
        {...props}
      />
    </AnimatePresence>
  )
}

export default CommandWrapper
