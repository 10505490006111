import { get } from 'lodash'
import React, { useContext } from 'react'
import { StateStore } from '../Store'
import ValidationRuleDialogue from './ValidationRuleDialogue'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css'

type Props = {}

type ValidationRule = {
  name?: string
  object_name?: string
  description?: string
  formula?: string
  error_message?: string
  error_display_field?: string
  header?: string
  active?: boolean
}

const AddValidationDialogue = (props: Props) => {
  const { state } = useContext(StateStore)
  const validationData: ValidationRule = get(state, 'modal.data', {})

  const initialValues = {
    name: validationData.name || '',
    objectName: validationData.object_name || '',
    description: validationData.description || '',
    formula: '',
    errorMessage: '',
    errorDisplayField: '',
    active: true,
  }

  return (
    <ValidationRuleDialogue
      values={initialValues}
      header='Create a Validation Rule'
    />
  )
}

export default AddValidationDialogue
