import React, { cloneElement, useContext } from 'react'
import { StateStore } from './Store'
import { get } from 'lodash'
import AddObjectDialogue from './FormDialogs/AddObjectDialogue'
import AddValidationDialogue from './FormDialogs/AddValidationRuleDialogue'
import EditValidationDialogue from './FormDialogs/EditValidationRuleDialogue'
import EditFieldDialogue from './FormDialogs/EditFieldDialogue'
import AddFieldDialogue from './FormDialogs/AddFieldDialogue'
import AddApexClassDialogue from './FormDialogs/AddApexClassDialogue'
import CreateObjectModal from './CsvUpload/CreateObjectModal'
import { IconWrapper } from '../components/CommandMenu'
import { HardDrive } from 'react-feather'
import AddRollupFieldDialogue from './FormDialogs/Rollups/AddRollupFieldDialogue'
import ReplaceFieldDialogue from './FormDialogs/ReplaceFieldDialogue'
import ReplacePicklistDialogue from './FormDialogs/ReplacePicklistDialogue'

export const MODAL_KEYS = [
  'commandMenu',
  'addField',
  'addObject',
  'editField',
  'addValidation',
  'editValidation',
  'addApexClass',
  'startFromNewObject',
  'rollupField',
  'replaceField',
  'replacePicklist',
]

export type ModalKey =
  | 'commandMenu'
  | 'addField'
  | 'addObject'
  | 'editField'
  | 'addValidation'
  | 'editValidation'
  | 'addApexClass'
  | 'startFromNewObject'
  | 'rollupField'
  | 'replaceField'
  | 'replacePicklist'

const ModalMap: Partial<Record<ModalKey, JSX.Element>> = {
  addObject: (
    <AddObjectDialogue
      icon={
        <IconWrapper backgroundColor={'#FFDCE1'}>
          <HardDrive
            width={17}
            height={17}
            style={{
              color: '#CA244D',
              strokeWidth: '2.5',
            }}
          />
        </IconWrapper>
      }
      title='Create an Object'
    />
  ),
  addField: <AddFieldDialogue />,
  addValidation: <AddValidationDialogue />,
  editValidation: <EditValidationDialogue />,
  editField: <EditFieldDialogue />,
  addApexClass: <AddApexClassDialogue />,
  startFromNewObject: <CreateObjectModal />,
  rollupField: <AddRollupFieldDialogue />,
  replaceField: <ReplaceFieldDialogue />,
  replacePicklist: <ReplacePicklistDialogue />,
}

const RootModal = () => {
  const { state } = useContext(StateStore)
  const currentOpenKey = get(state, 'modal.key')
  const currentOpen = Object.keys(ModalMap).find(e => e === currentOpenKey)

  if (currentOpen && ModalMap[currentOpen as ModalKey]) {
    return ModalMap[currentOpen as ModalKey] as any
  } else {
    return <div />
  }
}

export default RootModal
