import { get } from 'lodash'
import React, { useContext } from 'react'
import { StateStore } from './Store'
import { tw } from '@minupalaniappan/brise'

type Props = {}

const Backdrop = tw.div`
  w-full
  h-screen
  z-10
  opacity-20
  bg-black
  absolute
`

const ModalBackdrop = (props: Props) => {
  const { state } = useContext(StateStore)

  const commandMenuOpen = (get(state, 'modal.key', '') as string).length > 0

  return commandMenuOpen ? <Backdrop /> : <div />
}

export default ModalBackdrop
