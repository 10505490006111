import { useFormikContext } from 'formik'
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonPRops,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

type UpdateButtonProps = ChakraButtonPRops

const UpdateButton = ({ name, ...props }: UpdateButtonProps) => {
  const { submitForm, isValid } = useFormikContext()

  const [isDisabled, setIsDisabled] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (isValid) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [isValid])

  return (
    <ChakraButton
      {...(Object.assign({}, props) as any)}
      size={'sm'}
      bg='black'
      color='white'
      _hover={{ bg: '#474747' }}
      variant={'formSubmit'}
      onClick={async () => {
        setSubmitting(true)

        await submitForm()

        await setTimeout(() => {
          setSubmitting(false)
        }, 500)
      }}
      isLoading={submitting || props.isLoading}
      type='submit'
    >
      Update
    </ChakraButton>
  )
}

export default UpdateButton
