import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { get } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { StateStore } from '../Store'
import * as yup from 'yup'
import Select from '../Inputs/Select'
import Api from '../../lib/api'
import TextInput from '../Inputs/TextInput'
import SubmitButton from '../Inputs/SubmitButton'
import TextArea from '../Inputs/TextArea'
import Checkbox from '../Inputs/Checkbox'
import Form from '../Form'
import {
  FieldDataTypeOptions,
  FormulaFieldReturnTypeOptions,
} from '../constants'
import Image from 'next/image'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { FLAGS } from '../../lib/flags'

const FieldDialogueValidationSchema = yup.object().shape({
  objectName: yup.string().required(),
  dataType: yup
    .string()
    .oneOf(FieldDataTypeOptions.map(e => e.value))
    .required(),
  fieldName: yup.string().required(),
  description: yup.string(),
  helpText: yup.string(),
  pageLayouts: yup.array().of(yup.string()),
  picklistValues: yup.array(),
  allPageLayouts: yup.boolean(),
  isRequired: yup.boolean(),
  isExternalId: yup.boolean(),
  customRollup: yup.boolean(),
  label: yup.string().required(),
  defaultValue: yup.boolean(),
  formula: yup.string(),
  formulaFieldType: yup.string(),
  relatedTo: yup
    .string()
    .test(
      'is-required-if-lookup',
      'RelatedTo is required when dataType is lookup',
      function (value) {
        const { dataType } = this.parent
        return dataType === 'lookup' ? !!value : true
      },
    ),
})

const FieldDialogueValidationSteps = (values: any) => {
  const steps: yup.Schema<any>[] = [
    yup.object().shape({
      objectName: yup.string().required(),
      dataType: yup.string().oneOf(FieldDataTypeOptions.map(e => e.value)),
    }),
    yup.object().shape({
      fieldName: yup.string().required(),
      description: yup.string(),
      helpText: yup.string(),
      pageLayouts: yup.array().of(yup.string()),
      label: yup.string().required(),
      defaultValue: yup.boolean(),
      formula: yup.string(),
      picklistValues: yup.array(),
    }),
  ]

  if (values.dataType === 'formula') {
    steps.splice(
      1,
      0,
      yup.object().shape({
        formulaFieldType: yup.string().required(),
      }),
    )
  }

  return steps
}

const isStepValid = (values: any, stepIndex: number) => {
  const validationSteps = FieldDialogueValidationSteps(values)
  return validationSteps[stepIndex].isValidSync(values)
}

export type PageLayout = {
  full_name: string
}

export type FieldDialogueType = yup.InferType<
  typeof FieldDialogueValidationSchema
>

type FieldDialogueProps = {
  onSubmit: (values: any, selectedObjectPageLayouts: any[]) => Promise<void>
  initialValues: any
  onClose: () => void
  isObjectFieldDisabled: boolean
  showPageLayouts: boolean
  getModalHeaderText: (values: any) => string
  isOpen: boolean
  customFieldLoading: boolean
}

type generateCustomFieldParamsType = {
  objectName: string
  prompt: string
  fieldType: string
  formulaFieldType?: string
}

export const FieldDialogue = (props: FieldDialogueProps) => {
  const { state, dispatch } = useContext(StateStore)

  const [objects, setObjects] = useState([])
  const [selectedObject, setSelectedObject] = useState('')
  const [selectedObjectPageLayouts, setSelectedObjectPageLayouts] = useState<
    Array<PageLayout>
  >([])

  const [loading, setLoading] = useState(false)
  const [isAutofilling, setIsAutofilling] = useState(false)
  const [isGeneratingFormula, setIsGeneratingFormula] = useState(false)
  const [showFormulaPrompt, setShowFormulaPrompt] = useState(true)

  const fetchObjectPageLayouts = useCallback(async () => {
    setLoading(true)

    await Api.salesforce.objects.objectName.pageLayouts
      .getPageLayouts({ objectName: selectedObject || '' })
      .then(({ page_layouts }) => setSelectedObjectPageLayouts(page_layouts))
      .finally(() => setLoading(false))
  }, [selectedObject])

  const fetchObjects = useCallback(async () => {
    setLoading(true)

    await Api.salesforce.objects
      .getObjects()
      .then(({ objects }) => setObjects(objects))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    fetchObjects()
  }, [fetchObjects])

  const isAddRollupFieldEnabled = useFeatureFlagEnabled(FLAGS.Rollups)

  useEffect(() => {
    selectedObject && fetchObjectPageLayouts()
  }, [selectedObject, fetchObjectPageLayouts])

  const onClose = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        key: '',
        data: {},
      },
    })
  }

  const handleChange = (values: FieldDialogueType) => {
    if (values.objectName !== selectedObject) {
      setSelectedObject(values.objectName)
    }
  }

  const handleLabelEntry = (event: any, formik: any) => {
    if (event.target.value === '') {
      return
    }

    setIsAutofilling(true)

    if (formik.values.fieldName === '') {
      formik.setFieldValue(
        'fieldName',
        convertToSalesforceNameFormat(event.target.value),
      )
    }

    const generateCustomFieldParams: generateCustomFieldParamsType = {
      objectName: formik.values.objectName,
      prompt: formik.values.label,
      fieldType: formik.values.dataType,
    }

    if (formik.values.dataType === 'formula') {
      generateCustomFieldParams.formulaFieldType =
        formik.values.formulaFieldType
    }

    Api.salesforce.objects.objectName.customFields
      .generateFieldMetaText(generateCustomFieldParams)
      .then(({ description, help_text }) => {
        if (formik.values.description === '') {
          formik.setFieldValue('description', description)
        }

        if (formik.values.helpText === '') {
          formik.setFieldValue('helpText', help_text)
        }

        setIsAutofilling(false)
      })
  }

  const handleFieldNameEntry = (event: any, formik: any) => {
    formik.setFieldValue(
      'fieldName',
      convertToSalesforceNameFormat(event.target.value),
    )
  }

  const convertToSalesforceNameFormat = (name: string) => {
    return name.replace(/ /g, '_')
  }

  const generateFormula = async (formik: any) => {
    setIsGeneratingFormula(true)

    Api.salesforce.objects.objectName.customFields
      .generateFormula({
        objectName: formik.values.objectName,
        prompt: formik.values.prompt,
        fieldType: formik.values.formulaFieldType,
      })
      .then(({ formula }) => {
        formik.setFieldValue('formula', formula)
      })

    Api.salesforce.objects.objectName.customFields
      .generateFieldMetaText({
        objectName: formik.values.objectName,
        prompt: formik.values.prompt,
        fieldType: formik.values.formulaFieldType,
      })
      .then(({ help_text, description }) => {
        formik.setFieldValue('helpText', help_text)
        formik.setFieldValue('description', description)

        setShowFormulaPrompt(false)
        setIsGeneratingFormula(false)
      })
  }

  const baseRef = get(state, 'dialogue.rootRef.ref')

  const renderFieldDetails = (formik: any) => {
    return (
      <>
        <TextInput
          name='label'
          placeholder='Label (what user will see)'
          onBlur={(e: any) => {
            handleLabelEntry(e, formik)
          }}
        />
        <TextInput
          name='fieldName'
          placeholder='Field name'
          onBlur={(e: any) => {
            handleFieldNameEntry(e, formik)
          }}
        />
        <TextArea name='description' placeholder='Description' />
        <TextArea name='helpText' placeholder='Help text' />
        {(formik.values.dataType === 'picklist' ||
          formik.values.dataType === 'picklist_multi_select') && (
          <Select
            name='picklistValues'
            isMulti
            creatable
            className='nodrag nowheel'
            isLoading={loading}
            placeholder='Enter values for picklist'
          />
        )}
        {props.showPageLayouts && (
          <Select
            name='pageLayouts'
            isMulti
            className='nodrag nowheel'
            isDisabled={formik.values.allPageLayouts}
            isLoading={loading}
            placeholder='Select Page Layouts where field is displayed'
            options={(selectedObjectPageLayouts || []).map(pageLayout => ({
              label: decodeURIComponent(pageLayout.full_name),
              value: pageLayout.full_name,
            }))}
          />
        )}
        <Flex
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {props.showPageLayouts && (
            <Checkbox
              name='allPageLayouts'
              label='Add all page layouts'
              disabled={formik.values.pageLayouts.length > 0}
            />
          )}
          {formik.values.dataType === 'checkbox' && (
            <Checkbox name='defaultValue' label='Default Value' />
          )}
          {isAutofilling ? (
            <Spinner
              size={'sm'}
              position={'relative'}
              top={'1px'}
              right={'5px'}
            />
          ) : null}
        </Flex>
        <Checkbox name='isRequired' label='Required' disabled={false} />
        {(formik.values.dataType === 'text' ||
          formik.values.dataType === 'number' ||
          formik.values.dataType === 'email') && (
          <Checkbox name='isExternalId' label='External ID' disabled={false} />
        )}
      </>
    )
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={onClose}
      variant='custom'
      closeOnEsc
      closeOnOverlayClick
      portalProps={{
        containerRef: baseRef,
      }}
    >
      <ModalOverlay />
      <Form
        {...{
          validationSchema: FieldDialogueValidationSchema,
          initialValues: props.initialValues,
          isInitialValid: false,
          validateOnMount: true,
          onChange: handleChange,
          onSubmit: (values: any) => {
            return props.onSubmit(values, selectedObjectPageLayouts)
          },
          validateOnChange: true,
          validateOnBlur: true,
        }}
      >
        {formik => (
          <ModalContent>
            <ModalHeader>{props.getModalHeaderText(formik.values)}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {props.customFieldLoading ? (
                <Flex
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Spinner size={'sm'} />
                </Flex>
              ) : (
                <Flex direction={'column'} gap={5}>
                  <Flex direction='column' gap={5}>
                    <Select
                      className='nodrag nowheel'
                      name='objectName'
                      placeholder='Select object'
                      isDisabled={props.isObjectFieldDisabled}
                      isLoading={loading}
                      options={objects.map(({ label, name }) => ({
                        label,
                        value: name,
                      }))}
                    />
                    <Select
                      className='nodrag nowheel'
                      name='dataType'
                      placeholder='Data Type'
                      options={FieldDataTypeOptions}
                      isLoading={loading}
                    />

                    {[
                      'number',
                      'currency',
                      'double',
                      'int',
                      'date',
                      'date_time',
                    ].includes(formik.values.dataType) &&
                      isAddRollupFieldEnabled && (
                        <Flex justifyContent='space-between'>
                          <Checkbox
                            name='customRollup'
                            label='Enable custom rollup'
                          />
                        </Flex>
                      )}

                    {formik.values.dataType === 'lookup' ? (
                      <Select
                        className='nodrag nowheel'
                        name='relatedTo'
                        placeholder='Object Relation'
                        options={objects.map(({ label, name }) => ({
                          label,
                          value: name,
                        }))}
                        isLoading={loading}
                      />
                    ) : (
                      ''
                    )}
                  </Flex>
                  {isStepValid(formik.values, 0) ? (
                    <>
                      {formik.values.dataType === 'formula' ? (
                        <Flex direction='column' gap={5}>
                          <Select
                            className='nodrag nowheel'
                            name='formulaFieldType'
                            placeholder='Formula Return Type'
                            options={FormulaFieldReturnTypeOptions}
                            isLoading={loading}
                          />

                          {isStepValid(formik.values, 1) && (
                            <>
                              {showFormulaPrompt && (
                                <>
                                  <TextArea
                                    name='prompt'
                                    placeholder='Enter a prompt that Magik can use to write the formula for you'
                                  />
                                  <Button
                                    isDisabled={formik.values.prompt === ''}
                                    size={'sm'}
                                    onClick={() => generateFormula(formik)}
                                    bg='black'
                                    color='white'
                                    _hover={{ bg: '#474747' }}
                                  >
                                    {isGeneratingFormula
                                      ? 'Generating...'
                                      : 'Generate formula'}
                                    <div style={{ marginLeft: '18px' }}>
                                      <Image
                                        src={'/Icons/Magik.svg'}
                                        alt='BP'
                                        width={22}
                                        height={22}
                                      />
                                    </div>
                                  </Button>
                                </>
                              )}
                              {!showFormulaPrompt && (
                                <Text
                                  fontSize='xs'
                                  cursor='pointer'
                                  onClick={() => {
                                    setShowFormulaPrompt(true)
                                  }}
                                  _hover={{ textDecoration: 'underline' }}
                                  color='blackAlpha.800'
                                >
                                  Re-open formula prompt
                                </Text>
                              )}
                              <TextArea name='formula' placeholder='Formula' />
                              {renderFieldDetails(formik)}
                            </>
                          )}
                        </Flex>
                      ) : (
                        <Flex direction='column' gap={5}>
                          {renderFieldDetails(formik)}
                        </Flex>
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </Flex>
              )}
            </ModalBody>
            <ModalFooter>
              <SubmitButton />
            </ModalFooter>
          </ModalContent>
        )}
      </Form>
    </Modal>
  )
}

export default FieldDialogue
