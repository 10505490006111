import React, { ReactNode } from 'react'
import { components } from 'react-select'

export const CustomOption = ({ children, ...props }: any) => {
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps
  const newProps = { ...props, innerProps: rest }
  return (
    <components.Option {...newProps} className='custom-option'>
      {children}
    </components.Option>
  )
}

export default CustomOption
