import React, { HTMLProps } from 'react'
import { tw } from '@minupalaniappan/brise'
import Row from '../Layout/Row'
import Select from '../Inputs/Select'
import Form from '../Form'
import * as yup from 'yup'
import { Field, FieldArray } from 'formik'
import TextInput from '../Inputs/TextInput'
import DatePicker from 'react-datepicker'
import { get, startCase } from 'lodash'
import { CustomFilter } from './PipelineTable'
import styled from 'styled-components'
import { formatDateToYYYYMMDD, sortFields } from './utils'
import { Box } from '@chakra-ui/react'
import Column from '../Layout/Column'
import datePickerStyles from './DatePicker.module.css'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Plus } from 'react-feather'

const textComparators = ['equals', 'does_not_equal']
const picklistComparators = ['equals', 'does_not_equal']
const numberComparators = [
  'equals',
  'does_not_equal',
  'greater_than',
  'less_than',
]
const dateComparators = [
  'equals',
  'greater_than',
  'less_than',
  'last_n_days',
  'last_n_months',
]

const COMPARATOR_MAP: Record<string, Array<string>> = {
  string: textComparators,
  id: textComparators,
  text: textComparators,
  boolean: textComparators,
  textarea: textComparators,
  reference: textComparators,
  picklist: picklistComparators,
  multipicklist: picklistComparators,
  number: numberComparators,
  currency: numberComparators,
  percent: numberComparators,
  double: numberComparators,
  int: numberComparators,
  date: dateComparators,
  datetime: dateComparators,
}

type TableFilterBlockProps = {
  fields: any[]
  filters: CustomFilter[]
  setFilters: Function
  onSubmit: () => void
  objectName?: string
  advancedFilter?: boolean
  disallowMultipleConditions?: boolean
}

const AddFilter = tw.div<HTMLProps<HTMLDivElement>>`
  cursor-pointer
  rounded-[5.5px]
  bg-black
  text-center
  text-white
  hover:bg-[#474747]
  px-3.5
  py-1.5
  text-xs
  font-medium

  ${props => (props.disabled ? `opacity-50 pointer-events-none` : '')}
`

const SecondaryButton = tw.div<HTMLProps<HTMLDivElement>>`
  cursor-pointer
  rounded-[5.5px]
  bg-white
  text-center
  text-black
  border
  border-[#e4e4e7]
  px-3.5
  py-1.5
  text-xs
  font-medium
  hover:bg-[#fafafa]
`

const CaptionText = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #5a5a5a;
  margin-right: 15px;
`

const FilterRow = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: 10px;
  }
`

const TableFilterBlockContainer = styled.div<{ $shouldShowMargin?: boolean }>`
  background-color: white;
  min-width: 690px;
  max-width: 690px;
  border-radius: 8px;
  border: 1px solid #f2f1f1;
  padding: 15px;
  margin-top: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
`
const TertiaryButton = tw.div<HTMLProps<HTMLDivElement>>`
  cursor-pointer
  rounded-[5.5px]
  bg-white
  text-center
  text-black
  px-2
  py-1.5
  text-xs
  font-[500]
  hover:bg-[#fafafa]
`

function getComparatorSymbol(comparator: string) {
  switch (comparator) {
    case 'equals':
      return '='
    case 'does_not_equal':
      return '!='
    case 'greater_than':
      return '>'
    case 'less_than':
      return '<'
    default:
      return comparator
  }
}

function addFilter(filters: any[], fields: any[]): CustomFilter[] {
  const newFilters: CustomFilter[] = filters.map(f => {
    const field = fields.find(field => field.name === f.fieldName)

    const conditions = f.conditions.map(
      (c: { comparator: string; fieldValue: any }) => ({
        comparator: getComparatorSymbol(c.comparator),
        value:
          c.fieldValue instanceof Date
            ? formatDateToYYYYMMDD(c.fieldValue)
            : c.fieldValue
            ? c.fieldValue
            : field.type === 'boolean'
            ? 'false'
            : '',
      }),
    )

    const fieldLabel = field?.label
    const randomId = Math.floor(Math.random() * 500) + 1

    return {
      label: fieldLabel,
      key: f.fieldName,
      conditions: conditions,
      operator: f.operator,
      id: randomId,
    }
  })

  return newFilters
}

const RollupFilterBlock = (props: TableFilterBlockProps) => {
  const sortedFields = sortFields(props.fields)
  return (
    <TableFilterBlockContainer>
      <Form
        className='w-full'
        {...{
          initialValues: {
            objectName: props.objectName || '',
            advancedFilter: props.advancedFilter || false,
            filters: [
              {
                fieldName: null,
                conditions: [{}],
              },
            ],
          },
          validationSchema: yup.object().shape({
            objectName: yup.string().required(),
            advancedFilter: yup.boolean().optional(),
            filter: yup.object().shape({
              fieldName: yup.string().required(),
              operator: yup.string().required(),
              conditions: yup.array().of(
                yup.object({
                  comparator: yup.string().required(),
                  fieldValue: yup.string().required(),
                }),
              ),
            }),
          }),
          onSubmit: async values => {},
        }}
      >
        {formikProps => {
          console.log(formikProps.values)
          return (
            <div>
              <FieldArray
                name='filters'
                render={arrHelper => (
                  <div>
                    {formikProps.values.filters?.map(
                      (filter: any, index: number) => {
                        const conditionType = props.fields.find(
                          field =>
                            field.name ===
                            formikProps.values.filters[index].fieldName,
                        )?.type

                        console.log(
                          'abc',
                          get(formikProps.values, 'filters.0.fieldName'),
                        )
                        return (
                          <Row key={index}>
                            <Row>
                              <FieldArray
                                name={`filters.${index}.conditions`}
                                render={conditionArrHelper => {
                                  console.log('here')
                                  return (
                                    <div>
                                      {filter.conditions?.map(
                                        (_: any, conditionIndex: number) => {
                                          console.log(conditionIndex, 'SECOND')
                                          return (
                                            <>
                                              <FilterRow key={conditionIndex}>
                                                {conditionIndex === 0 && (
                                                  <Row y='center'>
                                                    <CaptionText>
                                                      Where
                                                    </CaptionText>
                                                    <div
                                                      style={{
                                                        width: '200px',
                                                        marginRight: '10px',
                                                      }}
                                                    >
                                                      <Select
                                                        name={`filters.${index}.fieldName`}
                                                        placeholder={'Field'}
                                                        {...{
                                                          options:
                                                            sortedFields?.map(
                                                              field => ({
                                                                value:
                                                                  field.name,
                                                                label:
                                                                  field.label,
                                                              }),
                                                            ) || [],
                                                        }}
                                                        value={get(
                                                          formikProps.values,
                                                          'filters.0.fieldName',
                                                        )}
                                                      />
                                                    </div>
                                                  </Row>
                                                )}
                                                {conditionIndex > 0 && (
                                                  <div
                                                    style={{
                                                      minWidth: '80px',
                                                      marginRight: '10px',
                                                      marginLeft: '173px',
                                                    }}
                                                  >
                                                    <Select
                                                      name={`filters.${index}.operator`}
                                                      placeholder={'And'}
                                                      {...{
                                                        options: [
                                                          {
                                                            value: 'and',
                                                            label: 'And',
                                                          },
                                                          {
                                                            value: 'or',
                                                            label: 'Or',
                                                          },
                                                        ],
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                                <div
                                                  style={{
                                                    minWidth: '150px',
                                                    maxWidth: '150px',
                                                    marginRight: '10px',
                                                  }}
                                                >
                                                  <Select
                                                    placeholder={'Equals'}
                                                    name={`filters.${index}.conditions.${conditionIndex}.comparator`}
                                                    isDisabled={
                                                      typeof formikProps.values
                                                        .filters[index] ===
                                                        'undefined' ||
                                                      typeof formikProps.values
                                                        .filters[index]
                                                        .fieldName ===
                                                        'undefined'
                                                    }
                                                    {...{
                                                      options: !(
                                                        typeof formikProps
                                                          .values.filters[
                                                          index
                                                        ] === 'undefined' ||
                                                        typeof formikProps
                                                          .values.filters[index]
                                                          .fieldName ===
                                                          'undefined'
                                                      )
                                                        ? COMPARATOR_MAP[
                                                            Object.keys(
                                                              COMPARATOR_MAP,
                                                            ).includes(
                                                              conditionType,
                                                            )
                                                              ? conditionType
                                                              : 'text'
                                                          ].map(
                                                            (
                                                              comparator: string,
                                                            ) => ({
                                                              value: comparator,
                                                              label:
                                                                startCase(
                                                                  comparator,
                                                                ),
                                                            }),
                                                          )
                                                        : undefined,
                                                    }}
                                                  />
                                                </div>
                                                <div
                                                  style={{
                                                    minWidth: '215px',
                                                    maxWidth: '215px',
                                                  }}
                                                >
                                                  {!(
                                                    typeof formikProps.values
                                                      .filters[index] ===
                                                      'undefined' ||
                                                    typeof formikProps.values
                                                      .filters[index]
                                                      .fieldName === 'undefined'
                                                  ) &&
                                                  conditionType &&
                                                  [
                                                    'text',
                                                    'string',
                                                    'id',
                                                    'reference',
                                                    'textarea',
                                                  ].includes(conditionType) ? (
                                                    <TextInput
                                                      name={`filters.${index}.conditions.${conditionIndex}.fieldValue`}
                                                      style={{
                                                        borderRadius: '4px',
                                                        height: '32px',
                                                        fontSize: '12px',
                                                      }}
                                                    />
                                                  ) : [
                                                      'number',
                                                      'currency',
                                                      'percent',
                                                      'double',
                                                      'int',
                                                    ].includes(
                                                      conditionType,
                                                    ) ? (
                                                    <TextInput
                                                      type='number'
                                                      name={`filters.${index}.conditions.${conditionIndex}.fieldValue`}
                                                    />
                                                  ) : [
                                                      'date',
                                                      'datetime',
                                                    ].includes(
                                                      conditionType,
                                                    ) ? (
                                                    <DatePicker
                                                      selected={get(
                                                        formikProps.values,
                                                        `filters.${index}.conditions.${conditionIndex}.fieldValue`,
                                                        new Date(),
                                                      )}
                                                      onChange={date =>
                                                        formikProps.setFieldValue(
                                                          `filters.${index}.conditions.${conditionIndex}.fieldValue`,
                                                          date,
                                                        )
                                                      }
                                                      calendarClassName={`${datePickerStyles.calendar} ${datePickerStyles.header}`}
                                                      className='border p-1 rounded relative bottom-[1px] border-gray-200 px-2 focus:outline-none w-full placeholder:text-xs text-xs py-[6px] h-[32px] mt-[1px]'
                                                      name={`filters.${index}.conditions.${conditionIndex}.fieldValue`}
                                                      placeholderText='Select Date'
                                                    />
                                                  ) : conditionType ===
                                                    'boolean' ? (
                                                    <Select
                                                      name={`filters.${index}.conditions.${conditionIndex}.fieldValue`}
                                                      className='min-w-[150px]'
                                                      options={[
                                                        {
                                                          value: 'true',
                                                          label: 'Yes',
                                                        },
                                                        {
                                                          value: 'false',
                                                          label: 'No',
                                                        },
                                                      ]}
                                                    />
                                                  ) : conditionType ===
                                                    'picklist' ? (
                                                    <Select
                                                      name={`filters.${index}.conditions.${conditionIndex}.fieldValue`}
                                                      className='min-w-[150px]'
                                                      options={props.fields
                                                        .find(
                                                          field =>
                                                            field.name ===
                                                            formikProps.values
                                                              .filters[index]
                                                              .fieldName,
                                                        )
                                                        ?.picklist_values.map(
                                                          (
                                                            picklist_value: any,
                                                          ) => ({
                                                            value:
                                                              picklist_value.value,
                                                            label:
                                                              picklist_value.label ||
                                                              picklist_value.value,
                                                          }),
                                                        )}
                                                    />
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>

                                                {conditionIndex === 0 ? (
                                                  <></>
                                                ) : (
                                                  <XCircleIcon
                                                    className='w-[19px] h-[19px] cursor-pointer'
                                                    style={{
                                                      marginTop: '6px',
                                                      marginLeft: '7px',
                                                    }}
                                                    color='#8D8D8D'
                                                    {...{
                                                      onClick: () =>
                                                        conditionArrHelper.remove(
                                                          conditionIndex,
                                                        ),
                                                    }}
                                                  />
                                                )}
                                              </FilterRow>
                                              {conditionIndex ===
                                                filter.conditions?.length - 1 &&
                                              formikProps.values.filters[0]
                                                .fieldName ? (
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginTop: '20px',
                                                    justifyContent:
                                                      'space-between',
                                                    marginBottom: '-3px',
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      marginLeft: '-9px',
                                                    }}
                                                  >
                                                    <TertiaryButton
                                                      {...{
                                                        onClick: () =>
                                                          conditionArrHelper.push(
                                                            {},
                                                          ),
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          marginTop: '2px',
                                                        }}
                                                      >
                                                        <Plus
                                                          height={13}
                                                          width={13}
                                                          strokeWidth='2.25'
                                                          style={{
                                                            marginRight: '5px',
                                                          }}
                                                        />
                                                        <span
                                                          style={{
                                                            marginTop: '1px',
                                                          }}
                                                        >
                                                          Add Filter
                                                        </span>
                                                      </div>
                                                    </TertiaryButton>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                    }}
                                                  >
                                                    <SecondaryButton
                                                      {...{
                                                        onClick: () => {
                                                          formikProps.resetForm()
                                                          // arrHelper.remove(
                                                          //   index,
                                                          // )
                                                        },
                                                      }}
                                                    >
                                                      Cancel
                                                    </SecondaryButton>
                                                    <AddFilter
                                                      style={{
                                                        marginLeft:
                                                          filter.conditions
                                                            ?.length === 1
                                                            ? '21px'
                                                            : '46px',
                                                        position: 'relative',
                                                        right:
                                                          filter.conditions
                                                            ?.length === 1
                                                            ? '1px'
                                                            : '26px',
                                                      }}
                                                      {...{
                                                        onClick: () => {
                                                          if (
                                                            !formikProps.errors
                                                              .filters
                                                          ) {
                                                            const newFilters: CustomFilter[] =
                                                              addFilter(
                                                                formikProps
                                                                  .values
                                                                  .filters,
                                                                props.fields,
                                                              )
                                                            const updatedFilters =
                                                              [
                                                                ...props.filters,
                                                                ...newFilters,
                                                              ]
                                                            props.setFilters(
                                                              updatedFilters,
                                                            )
                                                            formikProps.resetForm()
                                                            props.onSubmit()
                                                            // arrHelper.push({})
                                                          }
                                                        },
                                                      }}
                                                      disabled={
                                                        formikProps.errors
                                                          .filters
                                                      }
                                                    >
                                                      Apply
                                                    </AddFilter>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </>
                                          )
                                        },
                                      )}
                                    </div>
                                  )
                                }}
                              />
                            </Row>
                          </Row>
                        )
                      },
                    )}
                  </div>
                )}
              />
            </div>
          )
        }}
      </Form>
    </TableFilterBlockContainer>
  )
}

export default RollupFilterBlock
