import { Action } from '../Provider'
import { IconWrapper } from '../CommandMenu'
import { FileText, PlusSquare, HardDrive, Settings } from 'react-feather'

const ActionItems = (dispatch: (action: Action) => void, onUploadCsv: any) => [
  {
    key: 'addField',
    label: 'Create a Field',
    description: 'Deploy a new custom field to Salesforce',
    onClick: () => {
      dispatch({
        type: 'TOGGLE_MODAL',
        payload: {
          key: 'addField',
        },
      })
    },
    icon: (
      <IconWrapper backgroundColor={'#E1E9FF'}>
        <PlusSquare
          width={17}
          height={17}
          style={{
            color: '#3A5BC7',
            strokeWidth: '2.5',
          }}
        />
      </IconWrapper>
    ),
  },
  {
    key: 'addObject',
    label: 'Create an Object',
    description: 'Deploy a new custom object to Salesforce',
    onClick: () => {
      dispatch({
        type: 'TOGGLE_MODAL',
        payload: {
          key: 'addObject',
        },
      })
    },
    icon: (
      <IconWrapper backgroundColor={'#FFDCE1'}>
        <HardDrive
          width={17}
          height={17}
          style={{
            color: '#CA244D',
            strokeWidth: '2.5',
          }}
        />
      </IconWrapper>
    ),
  },
  {
    key: 'addValidation',
    label: 'Create a Validation Rule',
    description: 'Define field input rules and standards',
    onClick: () =>
      dispatch({
        type: 'TOGGLE_MODAL',
        payload: {
          key: 'addValidation',
        },
      }),
    icon: (
      <IconWrapper backgroundColor={'#FFDFB5'}>
        <Settings
          width={17}
          height={17}
          style={{
            color: '#CC4E00',
            strokeWidth: '2.5',
          }}
        />
      </IconWrapper>
    ),
  },
  {
    key: 'uploadCsv',
    label: 'Upload a CSV or Spreadsheet',
    description: "Set up an object's structure in bulk",
    onClick: () => {
      onUploadCsv()
    },
    icon: (
      <IconWrapper backgroundColor={'#E7E9E7'}>
        <FileText
          width={17}
          height={17}
          style={{
            color: '#60655F',
            strokeWidth: '2.5',
          }}
        />
      </IconWrapper>
    ),
  },
]

export default ActionItems
