import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  useToast,
  Text,
} from '@chakra-ui/react'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css'
import { get } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { StateStore } from '../Store'
import * as yup from 'yup'
import Select from '../Inputs/Select'
import Form from '../Form'
import Api from '../../lib/api'
import SubmitButton from '../Inputs/SubmitButton'
import TextArea from '../Inputs/TextArea'
import TextInput from '../Inputs/TextInput'
import Image from 'next/image'
import UpdateButton from '../Inputs/Update'

export type ObjectField = {
  name: string
  label: string
}

const AddObjectValidationSchema = yup.object().shape({
  name: yup.string().required(),
  objectName: yup.string().required(),
  description: yup.string().required(),
  formula: yup.string().required(),
  errorMessage: yup.string().required(),
  errorDisplayField: yup.string(),
  active: yup.boolean().required(),
})

export type AddObjectValidationRuleType = yup.InferType<
  typeof AddObjectValidationSchema
>

type ValueType = {
  name: string
  objectName: string
  description: string
  formula: string
  errorMessage: string
  errorDisplayField?: string | undefined
  active: boolean
}

type ValidationRuleProps = {
  values: ValueType
  header: string
}

const ValidationRuleDialogue = (props: ValidationRuleProps) => {
  const { state, dispatch } = useContext(StateStore)
  const modalKey = get(state, 'modal.key', '') as string
  const isOpen = ['editValidation', 'addValidation'].includes(modalKey)
  const [objects, setObjects] = useState<Array<ObjectField>>([])
  const [selectedObject, setSelectedObject] = useState('')
  const [selectedObjectFields, setSelectedObjectFields] = useState([])
  const [isGeneratingRule, setIsGeneratingRule] = useState(false)
  const [, setLoading] = useState(false)
  const toast = useToast()
  const user = get(state, 'user')

  const onSubmit = async (values: AddObjectValidationRuleType) => {
    await Api.salesforce.validationRules
      .createValidationRule({
        formula: values.formula,
        objectName: values.objectName,
        name: values.name,
        description: values.description,
        active: values.active,
        errorMessage: values.errorMessage,
        errorDisplayField: values.errorDisplayField,
      })
      .then(e => {
        if (e['errors']) {
          toast({
            description: e['errors'].map((e: any) => e.message).join('\n'),
            status: 'error',
            duration: 4000,
            position: 'top',
          })
        } else {
          toast({
            title: 'Success',
            description: 'Your rule is now live in Salesforce',
            status: 'success',
            duration: 4000,
            position: 'bottom-right',
            containerStyle: {
              marginBottom: '80px',
              marginRight: '25px',
            },
          })

          dispatch({
            type: 'SET_REFETCH_VALIDATION_RULES',
            payload: true,
          })

          onClose()
        }
      })
  }

  const onUpdate = async (
    values: AddObjectValidationRuleType,
    originalValues: ValueType,
  ) => {
    await Api.salesforce.validationRules
      .updateValidationRules({
        formula: values.formula,
        objectName: originalValues.objectName,
        name: originalValues.name,
        description: values.description,
        active: values.active || true,
        errorMessage: values.errorMessage,
        errorDisplayField: values.errorDisplayField,
      })
      .then(e => {
        if (e['errors']) {
          toast({
            description: e['errors'].map((e: any) => e.message).join('\n'),
            status: 'error',
            duration: 4000,
            position: 'top',
          })
        } else {
          toast({
            title: 'Success',
            description: 'Your validation rule has been updated',
            status: 'success',
            duration: 4000,
            position: 'bottom-right',
            containerStyle: {
              marginBottom: '80px',
              marginRight: '25px',
            },
          })

          dispatch({
            type: 'SET_REFETCH_VALIDATION_RULES',
            payload: true,
          })

          onClose()
        }
      })
  }

  const isModalEditValidationRule = props.header === 'Edit Validation Rule'

  const fetchObjectFields = useCallback(async () => {
    setLoading(true)

    await Api.salesforce.objects.objectName.fields
      .getFields({ objectName: selectedObject })
      .then(({ fields }) => {
        setSelectedObjectFields(fields)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [selectedObject])

  const fetchObjects = useCallback(async () => {
    setLoading(true)

    await Api.salesforce.objects
      .getObjects({})
      .then(({ objects }) => {
        setObjects(objects)
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    fetchObjects()
  }, [fetchObjects])

  useEffect(() => {
    if (selectedObject === '') {
      return
    }

    fetchObjectFields()
  }, [selectedObject, fetchObjectFields])

  const onClose = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        key: '',
        data: {},
      },
    })
  }

  const handleChange = (values: AddObjectValidationRuleType) => {
    if (values.objectName !== selectedObject) {
      setSelectedObject(values.objectName)
    }
  }

  const generateFormula = async (formik: any) => {
    setIsGeneratingRule(true)

    if (
      formik.values.description.toLowerCase().includes('term length') &&
      user?.name === 'Daniel Ruiz'
    ) {
      setTimeout(() => {
        const demoRule =
          "AND(\n    ISPICKVAL(Status, 'Activated'),\n    OR(\n        ISBLANK(StartDate),\n        ISBLANK(ContractTerm)\n    )\n)"

        formik.setFieldValue('formula', demoRule)

        if (formik.values.errorMessage === '') {
          formik.setFieldValue(
            'errorMessage',
            'You must enter a Start Date and Contract Term when activating a Contract.',
          )
        }

        if (formik.values.name === '') {
          formik.setFieldValue(
            'name',
            'Contract_Activation_Date_and_Term_Length',
          )
        }

        formik.setFieldValue('errorDisplayField', 'StartDate')

        setIsGeneratingRule(false)
      }, 3000)
    } else {
      Api.salesforce.validationRules
        .generateValidationRule({
          objectName: formik.values.objectName,
          prompt: formik.values.description,
        })
        .then(
          ({ validation_rule, error_message, name, error_display_field }) => {
            formik.setFieldValue('formula', validation_rule)

            if (formik.values.errorMessage === '') {
              formik.setFieldValue('errorMessage', error_message)
            }

            if (formik.values.name === '') {
              formik.setFieldValue('name', name)
            }

            if (formik.values.errorDisplayField === '') {
              formik.setFieldValue('errorDisplayField', error_display_field)
            }

            setIsGeneratingRule(false)
          },
        )
    }
  }

  const handleFieldNameEntry = (event: any, formik: any) => {
    formik.setFieldValue(
      'name',
      convertToSalesforceNameFormat(event.target.value),
    )
  }

  const convertToSalesforceNameFormat = (name: string) => {
    return name.replace(/ /g, '_')
  }

  const baseRef = get(state, 'dialogue.rootRef.ref')

  const numberOfRows = (formula: string) => (formula || '').split('\n').length
  const rowsProp = (formula: string) =>
    !!formula ? { rows: numberOfRows(formula) } : { rows: 2 }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      portalProps={{
        containerRef: baseRef,
      }}
      closeOnEsc
      closeOnOverlayClick
    >
      <ModalOverlay />
      <Form
        {...{
          validationSchema: AddObjectValidationSchema,
          initialValues: props.values,
          onSubmit: async (values: AddObjectValidationRuleType) => {
            modalKey === 'editValidation'
              ? onUpdate(values, props.values)
              : onSubmit(values)
          },
          validateOnChange: true,
          validateOnBlur: true,
          onChange: handleChange,
        }}
      >
        {formik => (
          <ModalContent>
            <ModalHeader>
              <Text fontWeight={700}>{props.header}</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction='column' gap={5}>
                <div style={{ marginTop: '-12px' }}>
                  <Text as='sub' fontWeight={550} style={{ marginLeft: '4px' }}>
                    Object
                  </Text>
                  <div style={{ marginTop: '5px' }}>
                    <Select
                      className='nodrag nowheel'
                      name='objectName'
                      options={objects.map(object => {
                        return { label: object.label, value: object.name }
                      })}
                      placeholder='Object name'
                      isDisabled={isModalEditValidationRule}
                    />
                  </div>
                </div>
                <div style={{ marginTop: '-12px' }}>
                  <Text as='sub' fontWeight={550} style={{ marginLeft: '4px' }}>
                    Description
                  </Text>
                  <TextArea style={{ marginTop: '5px' }} name='description' />
                  <Text as='sub' color='blackAlpha.800'>
                    Describe the validation rule you want in simple, plain
                    language and we&apos;ll take care of the rest.
                  </Text>
                </div>
                <Button
                  size={'sm'}
                  onClick={() => generateFormula(formik)}
                  bg='black'
                  color='white'
                  _hover={{ bg: '#474747' }}
                >
                  <>
                    {isGeneratingRule ? 'Generating...' : 'Generate formula'}
                    <div style={{ marginLeft: '18px' }}>
                      <Image
                        src={'/Icons/Magik.svg'}
                        alt='BP'
                        width={22}
                        height={22}
                      />
                    </div>
                  </>
                </Button>
                <div style={{ marginTop: '-12px' }}>
                  <Text as='sub' fontWeight={550} style={{ marginLeft: '4px' }}>
                    Formula
                  </Text>
                  <div style={{ marginTop: '5px' }}>
                    <Editor
                      value={formik.values.formula}
                      onValueChange={code => {
                        formik.setFieldValue('formula', code)
                      }}
                      highlight={code => highlight(code, languages.js)}
                      padding={10}
                      style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 14,
                        border: '1px solid #ddd',
                        borderRadius: '10px',
                      }}
                    />
                  </div>
                </div>
                <div style={{ marginTop: '-12px' }}>
                  <Text as='sub' fontWeight={550} style={{ marginLeft: '4px' }}>
                    Name
                  </Text>
                  <div style={{ marginTop: '5px' }}>
                    <TextInput
                      name='name'
                      onBlur={(e: any) => {
                        handleFieldNameEntry(e, formik)
                      }}
                      isDisabled={isModalEditValidationRule}
                    />
                  </div>
                </div>
                <div style={{ marginTop: '-12px' }}>
                  <Text as='sub' fontWeight={550} style={{ marginLeft: '4px' }}>
                    Error message
                  </Text>
                  <div style={{ marginTop: '5px' }}>
                    <TextArea rows={2} name='errorMessage' />
                  </div>
                </div>
                <div style={{ marginTop: '-12px' }}>
                  <Text as='sub' fontWeight={550} style={{ marginLeft: '4px' }}>
                    Display field
                  </Text>
                  <div style={{ marginTop: '5px' }}>
                    <Select
                      className='nodrag nowheel'
                      placeholder='Field name'
                      name='errorDisplayField'
                      options={selectedObjectFields.map(
                        (field: ObjectField) => {
                          return { label: field.label, value: field.name }
                        },
                      )}
                    />
                  </div>
                </div>
              </Flex>
            </ModalBody>
            <ModalFooter>
              {isModalEditValidationRule ? <UpdateButton /> : <SubmitButton />}
            </ModalFooter>
          </ModalContent>
        )}
      </Form>
    </Modal>
  )
}

export default ValidationRuleDialogue
