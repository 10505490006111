import { FieldHookConfig, useField } from 'formik'
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  Text,
} from '@chakra-ui/react'

type CheckboxProps = ChakraCheckboxProps &
  FieldHookConfig<'input'> & {
    label: string
  }

const Checkbox = ({ name, ...props }: CheckboxProps) => {
  const [field] = useField(name)

  return (
    <ChakraCheckbox
      {...(Object.assign({}, props, field) as any)}
      colorScheme='gray'
      isChecked={field.value}
    >
      <Text variant={'checkboxLabel'}>{props.label}</Text>
    </ChakraCheckbox>
  )
}

export default Checkbox
