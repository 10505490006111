import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  useToast,
  Box,
  Text,
  Checkbox,
} from '@chakra-ui/react'
import { get, replace } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { StateStore } from '../Store'
import Api from '../../lib/api'
import { DropdownIndicator } from '../InputsV2/Select'
import { baseStyles } from '../Onboarding/MarketplaceConfigurationModal'
import { Select } from 'chakra-react-select'

type Props = {}

type Option = {
  label: string
  value: string
}

interface Response {
  full_name: string
  success: boolean
  errors?: {
    message: string
    status_code: string
  }
}

function parseResponses(responses: Response[]) {
  let successCount = 0
  const errors: Array<{
    full_name: string
    message: string
    status_code: string
  }> = []

  responses.forEach(response => {
    if (response.success) {
      successCount++
    } else if (response.errors) {
      errors.push({
        full_name: response.full_name,
        message: response.errors.message,
        status_code: response.errors.status_code,
      })
    }
  })

  return {
    successCount,
    errors,
  }
}

const ReplaceFieldDialogue = (props: Props) => {
  const { state, dispatch } = useContext(StateStore)
  const isOpen = (get(state, 'modal.key', '') as string) === 'replaceField'
  const { selectedField, selectedType, references } = get(state, 'modal.data', {
    selectedField: { field_name: '', object_name: '' },
    selectedType: '',
    references: {},
  })

  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const [replaceValues, setReplaceValues] = useState<boolean>(false)

  const toast = useToast()
  const user = get(state, 'user')
  const [errors, setErrors] = useState([])

  const baseRef = get(state, 'dialogue.rootRef.ref')

  useEffect(() => {
    if (!!selectedField) {
      Api.salesforce.objects.objectName.fields
        .getFields({ objectName: selectedField.object_name })
        .then(({ fields }) => {
          if (Array.isArray(fields)) {
            const fieldOptions = fields.reduce((memo, field) => {
              if (
                field.type === selectedType &&
                field.custom &&
                field.name !== selectedField.field_name
              ) {
                memo.push({
                  label: field.label,
                  value: field.name,
                })
              }

              return memo
            }, [])

            setOptions(fieldOptions)
          }
        })
    }
  }, [selectedField, selectedType])

  const onClose = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        key: '',
        data: {},
      },
    })
  }

  const [options, setOptions] = useState([])

  const [selectedOption, setSelectedOption] = useState<Option | null>(null)

  const pollForDeployCompletion = (jobId: string) => {
    let tries = 0
    let jobCompleted = false

    let intervalId = setInterval(() => {
      tries++
      Api.salesforce.data.jobStatus({ job_id: jobId }).then(response => {
        jobCompleted = response.completed

        if (jobCompleted === true || tries >= 5) {
          clearInterval(intervalId)

          const allRecordsFailed =
            response.records_processed_count === response.records_failed_count

          const successCount =
            response.records_processed_count - response.records_failed_count

          if (response.status === 'failed') {
            setIsUpdating(false)
            toast({
              description: allRecordsFailed
                ? `All record updates failed while attempting to push to Salesforce`
                : `Some record updates failed while attempting to push to Salesforce`,
              status: 'error',
              duration: 8000,
              position: 'top',
            })
            onClose()
          }

          if (response.status === 'success') {
            setIsUpdating(false)
            if (response.errors.length > 0) {
              toast({
                title: 'Errors encountered',
                description: allRecordsFailed
                  ? `All record updates failed while attempting to push to Salesforce`
                  : `${successCount} out of ${response.records_processed_count} records were updated successfully. The rest experienced errors while attempting to push to Salesforce`,
                status: allRecordsFailed ? 'error' : 'warning',
                duration: 8000,
                position: 'top',
              })
              onClose()
            } else {
              toast({
                title: 'Success',
                description: 'Deployed all record updates to Salesforce',
                status: 'success',
                duration: 5000,
                position: 'top',
              })
              onClose()
            }
          }
        }
      })
    }, 5000)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      portalProps={{
        containerRef: baseRef,
      }}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      size='lg'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize='20px' fontWeight={600}>
            Select a Replacement Field
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            mt={10}
            background='rgb(248, 248, 248)'
            borderRadius='10px'
            pb={3}
            pt={4}
            px={5}
            mb={'50px'}
            h='full'
          >
            <Box>
              <Text pb={2}>
                Please select a field to replace{' '}
                {selectedField.field_name.replace('__c', '')}
              </Text>
              <Select
                options={options}
                chakraStyles={baseStyles({
                  color: 'black',
                  backgroundColor: 'white',
                  height: '40px',
                  fontSize: '13px',
                })}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: DropdownIndicator,
                }}
                onChange={e => {
                  setSelectedOption(e)
                }}
                placeholder='Select replacement field...'
              />
            </Box>
          </Box>
          <Box
            mt={10}
            background='rgb(248, 248, 248)'
            borderRadius='10px'
            pb={3}
            pt={4}
            px={5}
            mb={'50px'}
            h='full'
          >
            <Flex alignItems='center'>
              <Text pb={2}>
                Would you like to overwrite existing field values to the new
                field?
              </Text>
              <Checkbox
                colorScheme='purple'
                onChange={e => {
                  if (e.target.checked) {
                    setReplaceValues(true)
                  } else {
                    setReplaceValues(false)
                  }
                }}
              />
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            size={'sm'}
            isDisabled={!selectedOption || isUpdating}
            onClick={() => {
              setIsUpdating(true)
              // Make API Call Here
              Api.salesforce.customFields
                .updateReferences({
                  newField: selectedOption?.value ?? '',
                  oldField: selectedField.field_name,
                  objectName: selectedField.object_name,
                  references: JSON.stringify(references),
                })
                .then(data => {
                  const { successCount, errors } = parseResponses(
                    data.responses,
                  )

                  if (successCount > 0) {
                    toast({
                      description: `Successfuly updated ${successCount} references`,
                      status: 'success',
                      position: 'top',
                    })
                  }

                  if (errors.length > 0) {
                    errors.forEach(error => {
                      toast({
                        description: `Error updating references for ${error.full_name}: ${error.message}`,
                        status: 'error',
                        position: 'top',
                        duration: 5000,
                      })
                    })
                  }

                  if (!replaceValues) {
                    setIsUpdating(false)
                    onClose()
                  }
                })

              if (replaceValues) {
                Api.salesforce.data
                  .bulkReplace({
                    newField: selectedOption?.value ?? '',
                    oldField: selectedField.field_name,
                    objectName: selectedField.object_name,
                  })
                  .then(response => {
                    pollForDeployCompletion(response.job_id)
                  })
              }
            }}
            variant='primaryCTA'
          >
            {isUpdating ? 'Updating...' : 'Confirm'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ReplaceFieldDialogue
