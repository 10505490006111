import React, { useEffect, useState } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  VStack,
  RadioGroup,
  Radio,
  useDisclosure,
  Textarea,
  Button,
} from '@chakra-ui/react'

const FilterCombinationDropdown = ({
  selectedOption,
  onChange,
  currentListView,
}: {
  selectedOption: string
  onChange: (value: 'and' | 'or' | string) => void
  currentListView: any
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [showCustomFilterBlock, setShowCustomFilterBlock] = useState(false)
  const [customFormula, setCustomFormula] = useState('')

  useEffect(() => {
    if (selectedOption === 'and' || selectedOption === 'or') {
      setShowCustomFilterBlock(false)
    } else {
      setShowCustomFilterBlock(true)
      setCustomFormula(selectedOption === 'custom' ? '' : selectedOption)
    }
  }, [selectedOption])

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement='bottom-start'>
      <PopoverTrigger>
        <button
          style={{ color: '#5A5A5A' }}
          className='bg-white text-[12px] rounded-md px-2 py-1 leading-tight border-[#e4e4e7] border hover:bg-[#fafafa] whitespace-nowrap'
          onClick={onOpen}
        >
          {selectedOption === 'and'
            ? 'Match All'
            : selectedOption === 'or'
            ? 'Match Any'
            : 'Filter Logic'}
        </button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <RadioGroup
            colorScheme='purple'
            sx={{ fontSize: '10px' }}
            onChange={value => {
              if (value === 'custom') {
                setShowCustomFilterBlock(true)
                onChange(
                  currentListView?.join_filter?.custom_filter_logic || 'custom',
                )
              } else {
                onChange(value as 'and' | 'or')
                onClose()
                setShowCustomFilterBlock(false)
              }
            }}
            value={
              selectedOption !== 'and' && selectedOption !== 'or'
                ? 'custom'
                : selectedOption
            }
          >
            <VStack alignItems='flex-start'>
              <Radio value='and' size='sm'>
                Match all filters
              </Radio>
              <Radio value='or' size='sm'>
                Match any filters
              </Radio>
              <Radio value='custom' size='sm'>
                Custom filter logic
              </Radio>
            </VStack>
          </RadioGroup>
          {showCustomFilterBlock && (
            <>
              <Textarea
                value={customFormula}
                placeholder={`Add custom filter logic, for example:\n \n(1 AND 2) OR 3`}
                size='sm'
                mt={2}
                onChange={e => {
                  setCustomFormula(e.target.value)
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '8px',
                }}
              >
                <Button
                  size='sm'
                  onClick={() => {
                    onChange(customFormula)
                    onClose()
                  }}
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default FilterCombinationDropdown
