import { FieldHookConfig, useField } from 'formik'
import {
  TextareaProps as ChakraTextAreaProps,
  Textarea as ChakraTextArea,
} from '@chakra-ui/react'
import { get } from 'lodash'

type TextInputProps = ChakraTextAreaProps &
  FieldHookConfig<'input'> & {
    maxCharacter?: number
  }

const TextArea = ({ name, ...props }: TextInputProps) => {
  const [field] = useField(name)

  const updatedValue =
    field.value && name
      ? field.value.substring(0, props.maxCharacter || field.value.length)
      : props.value

  return (
    <ChakraTextArea
      {...(Object.assign({}, props, field) as any)}
      value={updatedValue}
      fontSize={'sm'}
    />
  )
}

export default TextArea
