import { FieldHookConfig, useField } from 'formik'
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Tooltip,
} from '@chakra-ui/react'
import { useEffect } from 'react'

type TextInputProps = ChakraInputProps &
  FieldHookConfig<'input'> & {
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    onCustomChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    tooltipMessage?: string | null
    defaultValue?: string
  } & {
    xs?: boolean
    forwadedRef?: any
  }

const TextInput = ({ ...props }: TextInputProps) => {
  const [field] = useField(props.name)

  let onChangeHandler

  if (props.onCustomChange) {
    onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onCustomChange) {
        props.onCustomChange(e)
      }

      field.onChange(e)
    }
  } else {
    onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      field.onChange(e)
    }
  }

  return props.tooltipMessage ? (
    <Tooltip label={props.tooltipMessage}>
      <ChakraInput
        fontSize={props.xs ? 'xs' : 'sm'}
        _placeholder={props.xs ? { fontSize: 'xs' } : { fontSize: 'sm' }}
        {...(Object.assign({}, field, props) as any)}
        onChange={onChangeHandler}
        value={field.value || props.defaultValue}
        onBlur={props.onBlur}
        height={'30px'}
        className='!ps-2 !bg-white'
        ref={props.forwadedRef}
      />
    </Tooltip>
  ) : (
    <ChakraInput
      fontSize={props.xs ? 'xs' : 'sm'}
      _placeholder={props.xs ? { fontSize: 'xs' } : { fontSize: 'sm' }}
      {...(Object.assign({}, field, props) as any)}
      onChange={onChangeHandler}
      value={field.value || props.defaultValue}
      onBlur={props.onBlur}
      height={props.height ?? '30px'}
      className='!ps-2 !bg-white'
      ref={props.forwadedRef}
    />
  )
}

export default TextInput
