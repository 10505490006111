import React from 'react'
import AddObjectDialogue from '../FormDialogs/AddObjectDialogue'
import { HardDrive } from 'react-feather'
import { IconWrapper } from '../CommandMenu'
import { useRouter } from 'next/router'

const CreateObjectModal = () => {
  const router = useRouter()

  return (
    <AddObjectDialogue
      icon={
        <IconWrapper backgroundColor={'black'}>
          <HardDrive
            width={17}
            height={17}
            style={{
              color: 'white',
              strokeWidth: '2.5',
            }}
          />
        </IconWrapper>
      }
      title={'New Object'}
      onSubmit={(fullName: string) => {
        const objectRoute = `/objects/${fullName}?tab=import`
        router.push(objectRoute)
      }}
    />
  )
}

export default CreateObjectModal
