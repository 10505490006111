import { get } from 'lodash'
import React, { useContext } from 'react'
import { StateStore } from '../Store'
import ValidationRuleDialogue from './ValidationRuleDialogue'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css'

type Props = {}

type ValidationRule = {
  name?: string
  object_name?: string
  description?: string
  formula?: string
  error_message?: string
  error_display_field?: string
  header?: string
  active?: boolean
}

const EditValidationDialogue = (props: Props) => {
  const { state } = useContext(StateStore)
  const validationData: ValidationRule = get(state, 'modal.data', {})

  const initialValues = {
    name: validationData.name || '',
    objectName: validationData.object_name || '',
    description: validationData.description || '',
    formula: validationData.formula || '',
    errorMessage: validationData.error_message || '',
    errorDisplayField: validationData.error_display_field || '',
    active: validationData.active || true
  };

  return (
    <ValidationRuleDialogue values={initialValues} header='Edit Validation Rule' />
  )
}

export default EditValidationDialogue
