export const FieldObjectTypeOptions = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Number',
    value: 'number',
  },
]

export const FieldDataTypeOptions = [
  { label: 'Checkbox', value: 'checkbox' },
  { label: 'Currency', value: 'currency' },
  { label: 'Date', value: 'date' },
  { label: 'Date / Time', value: 'date_time' },
  { label: 'Email', value: 'email' },
  { label: 'Encrypted Text', value: 'text_encrypted' },
  { label: 'Formula', value: 'formula' },
  { label: 'Html', value: 'text_area_rich' },
  { label: 'Location', value: 'geolocation' },
  { label: 'Long Text Area', value: 'text_area_long' },
  { label: 'Lookup', value: 'lookup' },
  { label: 'Multiselect Picklist', value: 'picklist_multi_select' },
  { label: 'Number', value: 'number' },
  { label: 'Percent', value: 'percent' },
  { label: 'Phone', value: 'phone' },
  { label: 'Picklist', value: 'picklist' },
  { label: 'Text', value: 'text' },
  { label: 'Text Area', value: 'text_area' },
  { label: 'Time', value: 'time' },
  { label: 'Url', value: 'url' },
]

export const FormulaFieldReturnTypeOptions = [
  { label: 'Checkbox', value: 'Checkbox' },
  { label: 'Currency', value: 'Currency' },
  { label: 'Date', value: 'Date' },
  { label: 'Date / Time', value: 'DateTime' },
  { label: 'Number', value: 'Number' },
  { label: 'Percent', value: 'Percent' },
  { label: 'Text', value: 'Text' },
  { label: 'Time', value: 'Time' },
]

export const FormulaFieldReturnTypeMap = {
  checkbox: 'Checkbox',
  currency: 'Currency',
  date: 'Date',
  date_time: 'Date / Time',
  number: 'Number',
  percent: 'Percent',
  text: 'Text',
  time: 'Time',
}
