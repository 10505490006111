import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  HStack,
  VStack,
  ButtonGroup,
  Box,
  Divider,
  useToast,
  Spinner,
  Center,
} from '@chakra-ui/react'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as yup from 'yup'
import Select from '../../Inputs/Select'
import Api from '../../../lib/api'
import Form from '../../Form'
import { Select as ChakraSelect, ChakraStylesConfig } from 'chakra-react-select'
import { TableFilterSegment } from '../../TableEditorV2/TableFilterCollection'
import { CustomFilter } from '../../TableEditorV2/PipelineTable'
import RollupFilterBlock from '../../TableEditorV2/RollupFilterBlock'
import { get } from 'lodash'
import { StateStore } from '../../Store'

enum RollupFormulas {
  Sum = 'SUM',
  Count = 'COUNT',
  Average = 'AVG',
  Min = 'MIN',
  Max = 'MAX',
}

function getFormulaForType(type: string, parentFieldType: string) {
  const baseOptions = [
    RollupFormulas.Count,
    RollupFormulas.Max,
    RollupFormulas.Min,
  ]

  switch (type) {
    case 'date':
    case 'datetime':
      if (parentFieldType === 'date' || parentFieldType === 'date_time') {
        return [RollupFormulas.Max, RollupFormulas.Min]
      }

      return [RollupFormulas.Count]
    default:
      return [RollupFormulas.Average, ...baseOptions, RollupFormulas.Sum]
  }
}

const RollupFieldValidationSchema = yup.object().shape({
  objectName: yup.string().required(),
  childObjectName: yup.string().required(),
  relationshipName: yup.object().shape({
    fieldName: yup.string(),
    relationshipName: yup.string(),
  }),
  childFieldName: yup.string().required(),
  fieldType: yup.string().required(),
  formula: yup.mixed<RollupFormulas>().oneOf(Object.values(RollupFormulas)),
})

const baseStyles: ChakraStylesConfig = {
  control: styles => ({
    ...styles,
    background: 'white',
    minHeight: '32px',
    maxHeight: '64px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    borderRadius: '4px',
    cursor: 'pointer',
    _focus: {
      outline: 'none !important',
      boxShadow: 'none !important',
      border: '1px solid !important',
      borderColor: 'black !important',
    },
  }),
  clearIndicator: styles => ({
    ...styles,
    width: '16px',
    height: '16px',
    position: 'relative',
    top: '2px',
    background: 'gray.200',
    '> svg': {
      width: '8px',
      height: '8px',
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: '12px',
      _hover: {
        backgroundColor: 'gray.200',
      },
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
    }
  },
  inputContainer: styles => ({
    ...styles,
    color: 'black',
    '&:focus': {
      outline: 'none',
      border: 'none',
      boxShadow: 'none',
    },
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: 'black',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
  }),
  container: styles => ({
    ...styles,
    height: 'fit-content',
    width: '100%',
    minHeight: '32px',
    borderRadius: '0px',
    '&:focus': {
      outline: 'none',
      border: 'none',
      boxShadow: 'none',
    },
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '0px 10px 0px 10px',
    borderRadius: '4px',
    gap: '5px',
    cursor: 'pointer',
    fontSize: 'xs',
    color: 'black',
    '&:focus': {
      outline: 'none',
      border: 'none',
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '5px 0',
    background: 'none !important',
    alignItems: 'flex-start',
    paddingRight: '5px',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    alignItems: 'flex-start',
    position: 'relative',
    bottom: '1px',
    background: 'none !important',
    color: '#BBBBBB',
    svg: {
      strokeWidth: '1px !important',
    },
    paddingLeft: '0px',
    paddingRight: '0px',
  }),
  input: styles => ({
    ...styles,
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: '12px',
  }),
  menu: styles => ({
    ...styles,
    fontSize: 'sm !important',
  }),
  menuList: styles => ({
    ...styles,
    fontSize: 'sm !important',
  }),
  noOptionsMessage: styles => ({
    ...styles,
    fontSize: '12px',
    paddingY: '1px',
  }),
}

export type FieldDialogueType = yup.InferType<
  typeof RollupFieldValidationSchema
>

type FieldDialogueProps = {}

function getFilterList(
  filters: Array<any>,
  availableFields: Array<any>,
): Array<CustomFilter> {
  if (filters.length) {
    return filters.reduce((memo: Array<CustomFilter>, filter) => {
      const foundField = availableFields.find(
        field => field.name === filter.field,
      )

      if (foundField) {
        memo.push({
          label: foundField.label,
          key: foundField.name,
          conditions: filter.conditions
            ? filter.conditions.map(
                (filterCondition: { operator: string; value: string }) => {
                  return {
                    comparator: filterCondition.operator,
                    value: filterCondition.value,
                  }
                },
              )
            : [
                {
                  comparator: filter.operator as any,
                  value: filter.value as any,
                },
              ],
          operator: ['and', 'or'].includes(filter.operator)
            ? filter.operator
            : 'and',
          id: foundField.name,
        })
      }

      return memo
    }, [])
  }

  return []
}
function createInitialState(
  objectName: string,
  rollupConfig: {
    filters: {
      parent: {
        filters: Array<{
          field: string
          operator: string
          value: string | number
        }>
      }
      child: {
        filters: Array<{
          field: string
          operator: string
          value: string | number
        }>
      }
    }
    formula: string
    child_object_field_name: string
    child_object_name: string
    child_object_relationship_name?: string
    child_object_linked_field?: string
  },
  relatedOjectFields: {
    [key: string]: [{ fieldName: string; relationshipName: string }]
  },
  childObjectFields: Array<any>,
  parentObjectFields: Array<any>,
) {
  const {
    child_object_name,
    child_object_field_name,
    child_object_relationship_name,
    formula,
    child_object_linked_field,
    filters,
  } = rollupConfig

  const {
    parent: { filters: parentFilterList },
    child: { filters: childFilterList },
  } = filters

  const relationshipName = relatedOjectFields[
    child_object_name ?? 'Contact'
  ].find(
    relationship =>
      relationship.relationshipName === child_object_relationship_name ||
      relationship.fieldName === child_object_linked_field,
  )

  const foundChildFieldName = childObjectFields.find(
    field => field.name === child_object_field_name,
  )

  const parentFilters = getFilterList(parentFilterList, parentObjectFields)

  const childFilters = getFilterList(childFilterList, childObjectFields)

  return {
    configState: {
      objectName,
      childObjectName: child_object_name ?? 'Contact',
      relationshipName: {
        label: relationshipName?.fieldName,
        value: relationshipName?.relationshipName,
      },
      childFieldName: {
        label: foundChildFieldName?.label,
        value: foundChildFieldName?.name,
      },
      formula: {
        label: formula,
        value: formula,
      },
    },
    parentFilters,
    childFilters,
  }
}

export default function AddRollupFieldDialogue(props: FieldDialogueProps) {
  //   const { objectName, isOpen, onClose, fieldName, fieldType } = props
  const { state, dispatch } = useContext(StateStore)

  const isOpen = (get(state, 'modal.key', '') as string) === 'rollupField'
  const fieldData = get(state, 'modal.data', {
    objectName: '',
    fieldName: '',
    rollupId: '',
    parentFieldType: '',
  })

  const { objectName, fieldName } = fieldData

  const toast = useToast()

  const [objects, setObjects] = useState<{
    [key: string]: [{ fieldName: string; relationshipName: string }]
  }>({})

  const [initialState, setInitialState] = useState<any>(null)

  const hasMountedRef = useRef(false)
  const [parentObjectFields, setParentObjectFields] = useState<Array<any>>([])

  const [childObjectName, setChildObjectName] = useState<string>('')

  const [childObjectFields, setChildObjectFields] = useState<Array<any>>([])

  const [loading, setLoading] = useState(false)

  const [parentFilters, setParentFilters] = useState<CustomFilter[]>([])
  const [childFilters, setChildFilters] = useState<CustomFilter[]>([])

  const [step, setStep] = useState(0)

  const fetchObjects = useCallback(async () => {
    if (objectName) {
      await Api.salesforce.objects.objectName.fields
        .fetchRelatedObjectFields({ objectName })
        .then(data => {
          if (data && data.child_relationships) {
            setObjects(data.child_relationships)
          }
        })
    }
  }, [objectName])

  const fetchParentObjectFields = useCallback(() => {
    if (objectName) {
      Api.salesforce.objects.objectName.fields
        .getFields({ objectName, disallowMultipleReferenceFields: false })
        .then(data => {
          if (data) {
            setParentObjectFields(data.fields)
          }
        })
    }
  }, [objectName])

  const fetchAndUpdateRollupConfiguration = useCallback(async () => {
    const found_rollup =
      await Api.salesforce.objects.objectName.rollupFields.fetchRollup({
        objectName,
        rollupId: fieldData.rollupId,
      })

    const relatedObjectsA = await Api.salesforce.objects.objectName.fields
      .fetchRelatedObjectFields({ objectName })
      .then(data => {
        if (data && data.child_relationships) {
          setObjects(data.child_relationships)
          return data.child_relationships
        }
      })

    const parentObjectFieldsA = await Api.salesforce.objects.objectName.fields
      .getFields({ objectName, disallowMultipleReferenceFields: false })
      .then(data => {
        if (data) {
          setParentObjectFields(data.fields)
          return data.fields
        }
      })

    const childObjectFieldsA = await Api.salesforce.objects.objectName.fields
      .getFields({
        objectName: found_rollup.child_object_name ?? 'Contact',
        disallowMultipleReferenceFields: false,
      })
      .then(data => {
        if (data) {
          setChildObjectFields(data.fields)
          return data.fields
        }
      })

    const { configState, parentFilters, childFilters } = createInitialState(
      objectName,
      found_rollup,
      relatedObjectsA,
      childObjectFieldsA,
      parentObjectFieldsA,
    )

    setInitialState(configState)
    setParentFilters(parentFilters)
    setChildFilters(childFilters)
  }, [objectName, fieldData])

  useEffect(() => {
    setLoading(true)

    if (!hasMountedRef.current) {
      if (fieldData.rollupId) {
        fetchAndUpdateRollupConfiguration()
      } else {
        fetchObjects()
        fetchParentObjectFields()
      }
      hasMountedRef.current = true
    }
    setLoading(false)
  }, [])

  const handleOnClose = () => {
    // Reset all states
    setChildObjectName('')
    setParentFilters([])
    setChildFilters([])
    setStep(0)

    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        key: '',
        data: {},
      },
    })
  }

  return (
    <Form
      {...{
        validationSchema: RollupFieldValidationSchema,
        isInitialValid: true,
        validateOnMount: true,
        initialValues:
          fieldData.rollupId && !!initialState
            ? initialState
            : {
                childObjectName: '',
              },
        onChange: (e, _) => {
          if (e.childObjectName && e.childObjectName !== childObjectName) {
            setChildObjectName(e.childObjectName)
            Api.salesforce.objects.objectName.fields
              .getFields({
                objectName: e.childObjectName,
                disallowMultipleReferenceFields: false,
              })
              .then(data => {
                setChildObjectFields(data.fields)
              })
          }
        },
        onSubmit: (values: any) => {
          return Api.salesforce.objects.objectName.fields
            .getFields({ objectName })
            .then(e => {})
        },
        validateOnChange: true,
        validateOnBlur: true,
      }}
    >
      {formik => {
        if (formik.touched['childObjectName']) {
          if (Object.keys(formik.values).length > 0) {
            setChildFilters([])
            formik.setValues({
              childObjectName: formik.values.childObjectName,
              relationshipName: '',
            })
            formik.setTouched({ childObjectName: false })
          }
        }

        const isNextDisabled =
          step === 0 ? Object.keys(formik.values).length < 4 : false
        const relationshipOptions =
          objects[formik.values.childObjectName]?.map(childRelationship => ({
            label: childRelationship.fieldName,
            value: childRelationship.relationshipName,
          })) ?? []

        return (
          <Modal
            isOpen={isOpen}
            onClose={() => {
              handleOnClose()
              formik.resetForm()
            }}
            closeOnEsc
            closeOnOverlayClick
          >
            <ModalOverlay />

            <ModalContent maxW='725px'>
              <ModalHeader>{` ${
                fieldData.rollupId ? 'Update' : 'Define'
              } Rollup for ${fieldName}`}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {(!!fieldData.rollupId ? !initialState : loading) ? (
                  <Center h='100px' w='full'>
                    <Spinner />
                  </Center>
                ) : (
                  <Flex direction={'column'} gap={5}>
                    {step === 0 ? (
                      <Flex direction='column' gap={5}>
                        <Text>
                          {fieldData.rollupId
                            ? 'Related object and its relationship'
                            : 'Select object and its relationship'}
                        </Text>

                        <Select
                          key='childObject'
                          className='nodrag nowheel'
                          name='childObjectName'
                          placeholder='Select object'
                          isLoading={loading}
                          options={Object.keys(objects).map(
                            childObjectName => ({
                              label: childObjectName,
                              value: childObjectName,
                            }),
                          )}
                        />

                        <ChakraSelect
                          key='relationshipName'
                          name='relationshipName'
                          placeholder='Select relationship'
                          className='nodrag nowheel'
                          value={formik.values.relationshipName}
                          options={relationshipOptions}
                          // defaultValue={
                          //   { label: 'AccountId', value: 'Opportunities' } ?? {}
                          // }
                          onChange={e => {
                            formik.setFieldValue('relationshipName', e)
                          }}
                          chakraStyles={baseStyles}
                        />

                        {!!childObjectFields?.length && (
                          <>
                            <Text>Select field to rollup</Text>
                            <ChakraSelect
                              key='childField'
                              name='childField'
                              placeholder='Select field to rollup'
                              className='nodrag nowheel'
                              value={formik.values.childFieldName ?? ''}
                              options={
                                childObjectFields
                                  .filter((field: any) =>
                                    fieldData.parentFieldType === 'date' ||
                                    fieldData.parentFieldType === 'date_time'
                                      ? ['date', 'datetime'].includes(
                                          field.type,
                                        )
                                      : [
                                          'date',
                                          'datetime',
                                          'number',
                                          'double',
                                          'int',
                                          'currency',
                                        ].includes(field.type),
                                  )
                                  .map(({ label, name, type }) => ({
                                    label: label,
                                    value: name,
                                    type,
                                  })) ?? []
                              }
                              onChange={e => {
                                formik.setFieldValue('childFieldName', e)
                              }}
                              chakraStyles={baseStyles}
                            />
                          </>
                        )}

                        {!!formik.values.childFieldName && (
                          <>
                            <Text>Select formula</Text>
                            <ChakraSelect
                              key='formula'
                              placeholder='Select formula'
                              className='nodrag nowheel'
                              value={formik.values.formula ?? ''}
                              options={
                                getFormulaForType(
                                  formik.values.childFieldName.type,
                                  fieldData.parentFieldType,
                                ).map(option => ({
                                  label: option,
                                  value: option,
                                })) ?? []
                              }
                              onChange={e => {
                                formik.setFieldValue('formula', e)
                              }}
                              chakraStyles={baseStyles}
                            />
                          </>
                        )}
                      </Flex>
                    ) : (
                      <VStack gap={5}>
                        {/* Parent Filters */}
                        <HStack w='full'>
                          <VStack w='full'>
                            <Box fontSize='14px' alignSelf='flex-start'>
                              <b>
                                {objectName} {`filters`}{' '}
                              </b>
                            </Box>
                            <Flex w='full' wrap={'wrap'} minH={'10px'} gap={2}>
                              {!!parentFilters.length &&
                                parentFilters.map((filter, index) => {
                                  return (
                                    <TableFilterSegment
                                      filterIndex={index}
                                      teams={[]}
                                      user={undefined}
                                      users={[]}
                                      filters={parentFilters}
                                      setFilters={setParentFilters}
                                      fields={parentObjectFields}
                                      filter={filter}
                                      forwardKey={filter.key}
                                      key={filter.key}
                                      onRemove={(filterId: number) => {
                                        setParentFilters(prev =>
                                          prev.filter(
                                            existingFilter =>
                                              existingFilter.id !== filterId,
                                          ),
                                        )
                                      }}
                                    />
                                  )
                                })}
                            </Flex>

                            <RollupFilterBlock
                              {...{
                                fields: parentObjectFields,
                                filters: parentFilters,
                                setFilters: setParentFilters,
                                objectName,
                                onSubmit: () => {},
                                disallowMultipleConditions: true,
                              }}
                            />
                          </VStack>
                        </HStack>
                        <Divider />
                        {/* Child Filters */}
                        <HStack w='full'>
                          <VStack w='full'>
                            <Box fontSize='14px' alignSelf='flex-start'>
                              <b>
                                {childObjectName} {`filters`}
                              </b>
                            </Box>
                            <Flex w='full' wrap={'wrap'} minH={'10px'} gap={2}>
                              {!!childFilters.length &&
                                childFilters.map((filter, index) => {
                                  return (
                                    <TableFilterSegment
                                      filterIndex={index}
                                      teams={[]}
                                      user={undefined}
                                      users={[]}
                                      filters={childFilters}
                                      setFilters={setChildFilters}
                                      fields={childObjectFields}
                                      filter={filter}
                                      forwardKey={filter.key}
                                      key={filter.key}
                                      onRemove={(filterId: number) => {
                                        setChildFilters(prev =>
                                          prev.filter(
                                            existingFilter =>
                                              existingFilter.id !== filterId,
                                          ),
                                        )
                                      }}
                                    />
                                  )
                                })}
                            </Flex>
                            <RollupFilterBlock
                              {...{
                                fields: childObjectFields,
                                filters: childFilters,
                                setFilters: setChildFilters,
                                objectName,
                                onSubmit: () => {},
                                disallowMultipleConditions: true,
                              }}
                            />
                          </VStack>
                        </HStack>
                      </VStack>
                    )}
                  </Flex>
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  {step > 0 && (
                    <Button
                      color='#767676'
                      background='#FFFFFF'
                      border='1px solid #EFEFEF'
                      _hover={{
                        background: '#EDEDED',
                      }}
                      onClick={() => {
                        if (step === 1 && !fieldData.rollupId) {
                          setParentFilters([])
                          setChildFilters([])
                        }
                        setStep(prev => prev - 1)
                      }}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    variant='primaryCTA'
                    isDisabled={isNextDisabled}
                    onClick={() => {
                      if (step === 1) {
                        const childObjectRelationshipName =
                          formik.values.relationshipName?.value

                        const parsedParentFilter = parentFilters.map(
                          parentFilter => {
                            return {
                              field: parentFilter.key,
                              operator: parentFilter.operator,
                              conditions: parentFilter.conditions.map(
                                condition => {
                                  return {
                                    operator: condition.comparator,
                                    value: condition.value,
                                  }
                                },
                              ),
                            }
                          },
                        )

                        const parsedChildFilters = childFilters.map(
                          childFilter => {
                            return {
                              field: childFilter.key,
                              operator: childFilter.operator,
                              conditions: childFilter.conditions.map(
                                condition => {
                                  return {
                                    operator: condition.comparator,
                                    value: condition.value,
                                  }
                                },
                              ),
                            }
                          },
                        )

                        let apiRequest

                        const requestArgs = {
                          objectName,
                          childObjectName: formik.values.childObjectName,
                          // If relationship name exists then use that, otherwise send object name and linked field name.
                          ...(childObjectRelationshipName
                            ? {
                                childObjectRelationshipName,
                              }
                            : {
                                childObjectLinkedFieldName:
                                  formik.values.relationshipName?.label,
                              }),
                          childObjectFieldApiName:
                            formik.values.childFieldName.value,
                          fieldType: 'number',
                          formula: formik.values.formula.value,
                          filters: {
                            parent: { filters: parsedParentFilter },
                            child: { filters: parsedChildFilters },
                          },
                        }

                        if (fieldData.rollupId) {
                          apiRequest =
                            Api.salesforce.objects.objectName.rollupFields.updateRollup(
                              { ...requestArgs, rollupId: fieldData.rollupId },
                            )
                        } else {
                          apiRequest =
                            Api.salesforce.objects.objectName.rollupFields.createRollup(
                              {
                                ...requestArgs,
                                objectFieldApiName: fieldName.includes('__c')
                                  ? fieldName
                                  : `${fieldName}__c`,
                              },
                            )
                        }

                        apiRequest
                          .then(data => {
                            toast({
                              description: `Your rollup has been saved. It might take a few minutes before the values are updated in Synch.`,
                              status: 'success',
                            })

                            dispatch({
                              type: 'TOGGLE_MODAL',
                              payload: {
                                action: '',
                                payload: '',
                              },
                            })
                          })
                          .catch(error => {
                            toast({
                              description: `Error creating rollup field: ${error.message}`,
                              status: 'error',
                            })
                          })
                      } else {
                        setStep(prev => prev + 1)
                      }
                    }}
                  >
                    {step === 1 ? 'Confirm' : 'Next'}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )
      }}
    </Form>
  )
}
