import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react'
import { get, isEmpty } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'

import Api from '../../lib/api'
import Form from '../Form'
import Checkbox from '../Inputs/Checkbox'
import SubmitButton from '../Inputs/SubmitButton'
import TextArea from '../Inputs/TextArea'
import TextInput from '../Inputs/TextInput'
import { StateStore } from '../Store'

type Props = {}

const AddObjectValidationSchema = yup.object().shape({
  fullName: yup.string().required(),
  label: yup.string().required(),
  description: yup.string().required(),
  deployed: yup.boolean(),
})

export type AddObjectType = yup.InferType<typeof AddObjectValidationSchema>

type AddObjectProps = {
  onSubmit?: (values: any) => void
  icon: any
  title: string
}

export type SavedObject = {
  fullName: string
  label: string
  description: string
}

export type FieldRecommendationResponse = {
  label: string
  field_type: string
  description: string
  picklist_values: string[]
  lookup_object_name?: string
}

export type FieldRecommendation = {
  label: string
  fieldName: string
  fieldType: string
  description: string
  objectName: string
  pageLayoutNames: string[]
  values: string[]
  selected: boolean
  relatedTo?: string
}

const CondensedModalHeader = styled.div`
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const AddObjectDialogue = (props: AddObjectProps) => {
  const { state, dispatch } = useContext(StateStore)
  const isOpen =
    (get(state, 'modal.key', '') as string) === 'addObject' ||
    (get(state, 'modal.key', '') as string) === 'startFromNewObject'
  const toast = useToast()
  const [step, setStep] = useState(1)
  const [savedObject, setSavedObject] = useState<SavedObject | any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [fieldRecommendations, setFieldRecommendations] = useState<
    Array<FieldRecommendation>
  >([])
  const [isAutofilling, setIsAutofilling] = useState(false)
  const user = get(state, 'user')

  const fetchFieldRecommendations = useCallback(() => {
    setIsLoading(true)

    if (
      savedObject.label.toLowerCase().includes('support') &&
      user?.name === 'Daniel Ruiz'
    ) {
      setTimeout(async () => {
        const recommendations = [
          {
            label: 'Ticket Number',
            field_type: 'text',
            description: 'Unique identifier for the ticket',
            picklist_values: [],
            lookup_object_name: null,
          },
          {
            label: 'Subject',
            field_type: 'text',
            description: 'Subject of the ticket',
            picklist_values: [],
            lookup_object_name: null,
          },
          {
            label: 'Description',
            field_type: 'text_area_long',
            description: 'Description of the ticket',
            picklist_values: [],
            lookup_object_name: null,
          },
          {
            label: 'Priority',
            field_type: 'picklist',
            description: 'Priority of the ticket',
            picklist_values: ['Low', 'Medium', 'High'],
            lookup_object_name: null,
          },
          {
            label: 'Status',
            field_type: 'picklist',
            description: 'Status of the ticket',
            picklist_values: ['Open', 'In Progress', 'Closed'],
            lookup_object_name: null,
          },
          {
            label: 'Assigned To',
            field_type: 'lookup',
            description: 'User assigned to the ticket',
            picklist_values: [],
            lookup_object_name: 'User',
          },
          {
            label: 'Created Date',
            field_type: 'date_time',
            description: 'Date the ticket was created',
            picklist_values: [],
            lookup_object_name: null,
          },
          {
            label: 'Closed Date',
            field_type: 'date_time',
            description: 'Date the ticket was closed',
            picklist_values: [],
            lookup_object_name: null,
          },
          {
            label: 'Resolution',
            field_type: 'text_area_long',
            description: 'Resolution of the ticket',
            picklist_values: [],
            lookup_object_name: null,
          },
          {
            label: 'Customer Satisfaction',
            field_type: 'percent',
            description: 'Customer satisfaction rating',
            picklist_values: [],
            lookup_object_name: null,
          },
        ]

        const response =
          await Api.salesforce.objects.objectName.pageLayouts.getPageLayouts({
            objectName: savedObject.fullName,
          })

        const pageLayoutNames = response.page_layouts.map(
          (layout: any) => layout.full_name,
        )
        const recs = ((recommendations as any) || []).map(
          (rec: FieldRecommendationResponse) => {
            return {
              ...rec,
              fieldName: convertToSalesforceNameFormat(rec.label),
              fieldType: rec.field_type,
              objectName: savedObject.fullName,
              pageLayoutNames: pageLayoutNames,
              values: rec.picklist_values,
              selected: true,
              relatedTo: rec.lookup_object_name,
              defaultValue: false,
            }
          },
        )
        setFieldRecommendations(recs)
        setIsLoading(false)
      }, 5000)
    } else {
      Api.salesforce.objects.objectName.customFields
        .customFieldRecommendations({
          objectName: savedObject.fullName,
          objectLabel: savedObject.label,
          description: savedObject.description,
        })
        .then(async ({ recommendations }) => {
          const response =
            await Api.salesforce.objects.objectName.pageLayouts.getPageLayouts({
              objectName: savedObject.fullName,
            })

          const pageLayoutNames = response.page_layouts.map(
            (layout: any) => layout.full_name,
          )
          const recs = (recommendations || []).map(
            (rec: FieldRecommendationResponse) => {
              return {
                ...rec,
                fieldName: convertToSalesforceNameFormat(rec.label),
                fieldType: rec.field_type,
                objectName: savedObject.fullName,
                pageLayoutNames: pageLayoutNames,
                values: rec.picklist_values,
                selected: true,
                relatedTo: rec.lookup_object_name,
                defaultValue: false,
              }
            },
          )

          setFieldRecommendations(recs)
          setIsLoading(false)
        })
    }
  }, [savedObject.fullName, savedObject.label, savedObject.description])

  useEffect(() => {
    if (!isEmpty(savedObject)) {
      fetchFieldRecommendations()
    }
  }, [savedObject, fetchFieldRecommendations])

  const onClose = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        key: '',
        data: {},
      },
    })
  }

  const initialValues = {
    fullName: '',
    label: '',
    description: '',
    deployed: false,
  } as AddObjectType

  const baseRef = get(state, 'dialogue.rootRef.ref')

  const handleFieldSelection = (e: any, name: string) => {
    const updatedFieldRecommendations = fieldRecommendations.map(
      (obj: FieldRecommendation) =>
        obj.fieldName === name ? { ...obj, selected: e.target.checked } : obj,
    )

    setFieldRecommendations(updatedFieldRecommendations)
  }

  const getSelectedFieldRecommendations = () => {
    return fieldRecommendations.filter(rec => rec.selected)
  }

  const numberOfSelectedFields = getSelectedFieldRecommendations().length

  const convertToSalesforceNameFormat = (name: string) => {
    return name.replace(/ /g, '_')
  }

  const handleLabelEntry = (event: any, formik: any) => {
    if (event.target.value === '') {
      return
    }

    setIsAutofilling(true)

    if (formik.values.fullName === '') {
      formik.setFieldValue(
        'fullName',
        convertToSalesforceNameFormat(event.target.value),
      )
    }

    Api.salesforce.objects
      .generateObject({
        prompt: formik.values.label,
      })
      .then(async ({ description }) => {
        if (formik.values.description === '') {
          formik.setFieldValue('description', description)
        }

        await setTimeout(() => {
          setIsAutofilling(false)
        }, 500)
      })
  }

  const handleFullNameEntry = (event: any, formik: any) => {
    formik.setFieldValue(
      'fullName',
      convertToSalesforceNameFormat(event.target.value),
    )
  }

  const deployFields = () => {
    getSelectedFieldRecommendations().forEach(field => {
      Api.salesforce.objects.objectName.customFields.createCustomField(field)
    })

    toast({
      description: `Deploying ${numberOfSelectedFields} fields for ${savedObject.label}`,
      status: 'success',
      duration: 4000,
      position: 'top',
    })

    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc
      closeOnOverlayClick
      variant={'custom'}
      portalProps={{
        containerRef: baseRef,
      }}
    >
      <ModalOverlay />
      {step == 2 ? (
        <ModalContent>
          <ModalHeader>
            <CondensedModalHeader>{`Field recommendations for ${savedObject.label}`}</CondensedModalHeader>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <Spinner />
            ) : (
              <Flex direction='column' gap={5}>
                {fieldRecommendations.map((rec, i) => {
                  return (
                    <>
                      <Flex direction={'column'}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            <Text>{`${rec.label}`}</Text>
                            <Text
                              fontSize='sm'
                              style={{ paddingTop: '1px', paddingLeft: '5px' }}
                            >{`(${rec.fieldType})`}</Text>
                          </div>
                          <Switch
                            size='md'
                            onChange={e => {
                              handleFieldSelection(e, rec.fieldName)
                            }}
                            isChecked={rec.selected}
                            colorScheme='purple'
                          />
                        </div>
                        <Text variant={'primarySubtitle'}>
                          {rec.description}
                        </Text>
                      </Flex>
                      {i !== fieldRecommendations.length - 1 && <Divider />}
                    </>
                  )
                })}
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              size={'sm'}
              isDisabled={numberOfSelectedFields === 0}
              onClick={deployFields}
              bg='black'
              color='white'
              _hover={{ bg: '#474747' }}
            >
              {numberOfSelectedFields === 0
                ? 'Deploy'
                : numberOfSelectedFields === 1
                ? 'Deploy 1 field'
                : `Deploy ${numberOfSelectedFields} fields`}
            </Button>
          </ModalFooter>
        </ModalContent>
      ) : (
        <Form
          {...{
            validationSchema: AddObjectValidationSchema,
            onChange: e => console.info(e),
            initialValues,
            onSubmit: async (values: AddObjectType) => {
              setSavedObject({
                fullName: values.fullName + '__c',
                label: values.label,
                description: values.description,
              })

              await Api.salesforce.objects
                .createObject({
                  fullName: values.fullName,
                  label: values.label,
                  description: values.description,
                  deployed: values.deployed || false,
                })
                .then((e: any) => {
                  if (e['errors']) {
                    toast({
                      description: e['errors']
                        .map((e: any) => e.message)
                        .join('\n'),
                      status: 'error',
                      duration: 4000,
                      position: 'top',
                    })
                  } else {
                    toast({
                      title: 'Success',
                      description: `Your object has been deployed to Salesforce`,
                      status: 'success',
                      duration: 4000,
                      position: 'bottom-right',
                      containerStyle: {
                        marginBottom: '80px',
                        marginRight: '25px',
                      },
                    })

                    if (props.onSubmit) {
                      props.onSubmit(values.fullName + '__c')
                      onClose()
                    } else {
                      setStep(2)
                    }
                  }
                })
            },
          }}
        >
          {formik => (
            <ModalContent>
              <ModalHeader>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '3px',
                  }}
                >
                  {props.icon}
                  <Text paddingLeft={'15px'} fontWeight={700}>
                    {props.title}
                  </Text>
                </div>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex direction='column' gap={5}>
                  <div style={{ marginTop: '-8px' }}>
                    <Text
                      as='sub'
                      fontWeight={550}
                      style={{ marginLeft: '4px' }}
                    >
                      Label
                    </Text>
                    <div style={{ marginTop: '5px' }}>
                      <TextInput
                        name='label'
                        borderRadius={'5px'}
                        height={'40px'}
                        onBlur={(e: any) => {
                          handleLabelEntry(e, formik)
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: '-8px' }}>
                    <Text
                      as='sub'
                      fontWeight={550}
                      style={{ marginLeft: '4px' }}
                    >
                      Full name
                    </Text>
                    <div style={{ marginTop: '5px' }}>
                      <TextInput
                        name='fullName'
                        borderRadius={'5px'}
                        height={'40px'}
                        onBlur={(e: any) => {
                          handleFullNameEntry(e, formik)
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: '-8px' }}>
                    <Text
                      as='sub'
                      fontWeight={550}
                      style={{ marginLeft: '4px' }}
                    >
                      Description
                    </Text>
                    <div style={{ marginTop: '5px' }}>
                      <TextArea
                        borderRadius={'5px'}
                        height={'120px'}
                        resize={'none'}
                        name='description'
                        style={{ fontSize: '13px' }}
                      />
                    </div>
                  </div>
                  <Flex
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <div>
                      <div>
                        <Text
                          as='sub'
                          fontWeight={550}
                          style={{ marginLeft: '4px' }}
                        >
                          Deployed
                        </Text>
                      </div>
                      <div style={{ marginLeft: '4px', marginTop: '10px' }}>
                        <Checkbox name='deployed' label='' />
                      </div>
                      <Text as='sub' color='blackAlpha.800'>
                        For this checkbox, if you select &apos;Deployed&apos;,
                        your new custom object will be immediately available for
                        use within Salesforce.
                      </Text>
                    </div>
                    {isAutofilling ? (
                      <Spinner
                        size={'sm'}
                        position={'relative'}
                        top={'2px'}
                        right={'5px'}
                      />
                    ) : null}
                  </Flex>
                </Flex>
              </ModalBody>
              <ModalFooter border='none'>
                <SubmitButton />
              </ModalFooter>
            </ModalContent>
          )}
        </Form>
      )}
    </Modal>
  )
}

export default AddObjectDialogue
