import { useToast } from '@chakra-ui/react'
import React, { useContext } from 'react'
import FieldDialogue from './FieldDialogue'
import { StateStore } from '../Store'
import Api from '../../lib/api'
import { get } from 'lodash'
import { FieldDataTypeOptions } from '../constants'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { FLAGS } from '../../lib/flags'

type AddFieldDialogueProps = {}
type Field = {
  objectName?: string
  dataType?: string
  description?: string
  label?: string
  fieldName?: string
  picklistValues?: any[]
  relatedTo?: string
}
export const AddFieldDialogue = (props: AddFieldDialogueProps) => {
  const { state, dispatch } = useContext(StateStore)
  const toast = useToast()

  const isOpen = (get(state, 'modal.key', '') as string) === 'addField'
  const fieldData: Field = get(state, 'modal.data', {})

  const handleClose = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        key: '',
        data: {},
      },
    })
  }

  const isAddRollupFieldEnabled = useFeatureFlagEnabled(FLAGS.Rollups)

  const handleSubmit = async (
    values: any,
    selectedObjectPageLayouts: any[],
  ) => {
    let pageLayouts = (values.pageLayouts || []) as Array<string>

    if (values.allPageLayouts) {
      pageLayouts = selectedObjectPageLayouts.map(
        pageLayout => pageLayout.full_name,
      )
    }

    const picklistValues = (values.picklistValues || []).map(
      (value: any) => value.value,
    )

    await Api.salesforce.objects.objectName.customFields
      .createCustomField({
        fieldName: values.fieldName,
        fieldType: values.dataType,
        description: values.description,
        helpText: values.helpText,
        pageLayoutNames: pageLayouts,
        defaultValue: values.defaultValue,
        objectName: values.objectName,
        label: values.label,
        formula: values.formula,
        formulaFieldType: values.formulaFieldType,
        values: picklistValues,
        relatedTo: values.relatedTo,
        externalId: values.isExternalId,
        required: values.isRequired,
      })
      .then(e => {
        if (e['errors']) {
          toast({
            description: e['errors'].map((e: any) => e.message).join('\n'),
            status: 'error',
            duration: 4000,
            position: 'top',
          })
        } else {
          toast({
            title: 'The field has been deployed to Salesforce',
            description:
              "We'll need a couple minutes before it's visible in Synch",
            status: 'success',
            duration: 4000,
            position: 'bottom-right',
            containerStyle: {
              marginBottom: '80px',
              marginRight: '25px',
            },
          })

          dispatch({
            type: 'SET_REFETCH_FIELDS',
            payload: true,
          })

          if (isAddRollupFieldEnabled && values.customRollup) {
            handleClose()

            dispatch({
              type: 'TOGGLE_MODAL',
              payload: {
                key: 'rollupField',
                data: {
                  objectName: values.objectName,
                  fieldName: values.fieldName,
                  parentFieldType: values.dataType,
                },
              },
            })
          } else {
            handleClose()
          }
        }
      })
  }

  const findLabelByValue = (value: string) => {
    const foundFieldDataType = FieldDataTypeOptions.find(f => f.value === value)
    return foundFieldDataType ? foundFieldDataType.label : null
  }

  const getModalHeaderText = (values: any) => {
    let text = 'Create '

    if (values.label) {
      text += values.label
    } else if (values.dataType) {
      text += findLabelByValue(values.dataType)
    } else {
      text += 'a new'
    }

    text += ' Field'
    return text
  }

  return (
    <FieldDialogue
      onSubmit={handleSubmit}
      initialValues={{
        objectName: fieldData.objectName || '',
        dataType: fieldData.dataType || '',
        fieldName: fieldData.fieldName || '',
        description: fieldData.description || '',
        helpText: '',
        pageLayouts: [],
        allPageLayouts: true,
        label: fieldData.label || '',
        relatedTo: fieldData.relatedTo || '',
        defaultValue: false,
        prompt: '',
        formulaFieldType: '',
        formula: '',
        picklistValues: fieldData.picklistValues || [],
      }}
      onClose={handleClose}
      isObjectFieldDisabled={false}
      showPageLayouts={true}
      getModalHeaderText={getModalHeaderText}
      isOpen={isOpen}
      customFieldLoading={false}
    />
  )
}

export default AddFieldDialogue
