import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  useToast,
  Textarea,
  Box,
  Text,
} from '@chakra-ui/react'
import { get } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { StateStore } from '../Store'
import Api from '../../lib/api'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css'
import Image from 'next/image'

type Props = {}

const AddApexClassDialogue = (props: Props) => {
  const { state, dispatch } = useContext(StateStore)
  const isOpen = (get(state, 'modal.key', '') as string) === 'addApexClass'
  const [isGeneratingClass, setIsGeneratingClass] = useState(false)
  const [isDeploying, setIsDeploying] = useState(false)
  const [description, setDescription] = useState('')
  const [content, setContent] = useState('')
  const [name, setName] = useState('')
  const [, setLoading] = useState(false)
  const toast = useToast()
  const user = get(state, 'user')
  const [errors, setErrors] = useState([])
  const [showPrompt, setShowPrompt] = useState(true)

  const placeholders = [
    "Create an Apex class named 'AccountUtility' that has a static method which accepts an Account name as a string, queries for the Account with that name, and returns the Account's Id.",
    "Create an Apex class 'OrderHandler' that has a method to process orders. This method should accept a List of Order Ids, query for those Orders and related OrderItems, and for each order calculate the total amount by summing up the OrderItem amounts and update the 'TotalAmount' field on the Order.",
    "Create an Apex class named 'LeadProcessor'. It should have a method that accepts a List of Lead Ids. For each Lead, if its Status is 'Open - Not Contacted' and it was created more than 7 days ago, the method should update the Lead's Status to 'Working - Contacted'.",
    "Create an Apex class named 'OpportunityHelper'. It should contain a method that accepts an Opportunity Id. The method should query for the Opportunity and related OpportunityLineItems, and if any of the LineItems have a Quantity greater than 100, it should update the Opportunity's 'LargeDeal__c' checkbox field to true.",
    "Create an Apex class named 'CaseEscalator' that contains a method which accepts a List of Case Ids. For each Case, if the Case's Status is 'New' and it was created more than 24 hours ago, the method should update the Case's Status to 'Escalated'.",
    "Create an Apex class named 'ProductValidator'. It should have a static method that accepts a Product2 Id. The method should query for the Product2 and its related PricebookEntries, and if any of the PricebookEntries have a UnitPrice greater than $1000, it should throw a custom exception 'HighPriceException'.",
  ]

  const [placeholderIndex, setPlaceholderIndex] = useState(
    Math.floor(Math.random() * placeholders.length),
  )

  const baseRef = get(state, 'dialogue.rootRef.ref')

  const onClose = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        key: '',
        data: {},
      },
    })
  }

  const generateApexClass = () => {
    setIsGeneratingClass(true)

    Api.salesforce.apexClasses
      .relevantObjects({
        description: description,
      })
      .then(({ relevant_objects }) => {
        Api.salesforce.apexClasses
          .generateApexClass({
            description: description,
            relevant_objects: relevant_objects,
          })
          .then(({ apex_class, class_name }) => {
            setContent(apex_class)
            setName(class_name)
            setShowPrompt(false)

            setIsGeneratingClass(false)
            setErrors([])
          })
      })
  }

  const pollForDeployCompletion = (deployId: string) => {
    let tries = 0
    let completionStatus = false

    let intervalId = setInterval(() => {
      tries++
      Api.salesforce.apexClasses
        .getApexClassDeploymentStatus({ deploy_id: deployId })
        .then(response => {
          completionStatus = response.completed

          if (completionStatus === true || tries >= 20) {
            clearInterval(intervalId)

            if (response.status === 'Failed') {
              setIsDeploying(false)

              toast({
                description: `${response.errors.join(', ')}`,
                status: 'error',
                duration: 4000,
                position: 'top',
              })

              setErrors(response.errors)
            }

            if (response.status === 'Succeeded') {
              setIsDeploying(false)

              toast({
                description: 'Successfully deployed Apex Class to Salesforce',
                status: 'success',
                duration: 4000,
                position: 'top',
              })

              onClose()
            }
          }
        })
    }, 4000)
  }

  const handleDeploy = () => {
    setIsDeploying(true)
    Api.salesforce.apexClasses
      .deployApexClass({
        modified_apex_classes: [
          {
            apex_class_name: name,
            class_content: content,
          },
        ],
        modified_apex_triggers: [],
      })
      .then((data: any) => {
        pollForDeployCompletion(data.deploy_id)
      })
  }

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      portalProps={{
        containerRef: baseRef,
      }}
      closeOnEsc
      closeOnOverlayClick
      size='3xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontWeight={700}>Create an Apex Class</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction='column' gap={5}>
            {showPrompt && (
              <Box position='relative'>
                <Textarea
                  value={description}
                  onChange={handleDescriptionChange}
                  placeholder={placeholders[placeholderIndex]}
                  rows={10}
                  fontSize='sm'
                />
                <Button
                  size={'sm'}
                  onClick={() => generateApexClass()}
                  position='absolute'
                  right='10px'
                  bottom='10px'
                  bg='black'
                  color='white'
                  _hover={{ bg: '#474747' }}
                  zIndex='2'
                  width='175px'
                >
                  {isGeneratingClass ? 'Generating...' : 'Generate Apex'}
                  <div style={{ marginLeft: '20px' }}>
                    <Image
                      src={'/Icons/Magik.svg'}
                      alt='BP'
                      width={20}
                      height={20}
                    />
                  </div>
                </Button>
              </Box>
            )}
            {!showPrompt && (
              <Text
                fontSize='sm'
                cursor='pointer'
                onClick={() => {
                  setShowPrompt(true)
                }}
                _hover={{ textDecoration: 'underline' }}
                color='blackAlpha.800'
                margin='auto'
              >
                Re-open prompt
              </Text>
            )}
            {content && (
              <Editor
                value={content}
                onValueChange={code => setContent(code)}
                highlight={code => highlight(code, languages.js)}
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 14,
                  border: '1px solid #ddd',
                  borderRadius: '10px',
                }}
              />
            )}
          </Flex>
          {errors.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              <div>
                <Text as='sub' color='red.500'>
                  Errors:
                </Text>
              </div>
              {errors.map((error, index) => (
                <div key={index}>
                  <Text as='sub' color='red.500'>
                    {error}
                  </Text>
                </div>
              ))}
            </div>
          )}
        </ModalBody>
        {content && (
          <ModalFooter>
            <Button
              size={'sm'}
              isDisabled={!content}
              onClick={handleDeploy}
              bg='#3D4BD0'
              color='white'
              _hover={{ bg: '#2E3A8A' }}
            >
              {isDeploying ? 'Deploying...' : 'Deploy'}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export default AddApexClassDialogue
