import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import styled from 'styled-components'
import { tw } from '@minupalaniappan/brise'
import { CiViewList } from 'react-icons/ci'

const ListViewSelectorContainer = tw.div<any>`
    flex
    items-center
    rounded-md
    text-xs
    bg-white
    border
    border-[#e4e4e7]
    px-3
    py-1
    font-medium
    cursor-pointer
    leading-tight
    whitespace-nowrap
    hover:bg-[#fafafa]
`

const SelectorText = styled.div`
  padding-left: 8px;
  font-size: 14px;
`

const ListViewList = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 12px 0px;
  font-size: 12px;
  padding: 7px 7px;
  padding-bottom: 8px;
  border: 1px solid #e3e8ef;
  width: 180px;
  max-height: 360px;
  overflow: auto;
  font-weight: 500;
  color: #5b5a5a;
`

export const ListViewOption = styled.div<{
  selected?: boolean
  fontSize?: string
}>`
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 14px;
  background-color: ${props => (!props.selected ? 'white' : '#f5f6f8')};
  ${props => props.fontSize && `font-size: ${props.fontSize};`}

  &:hover {
    background-color: #f5f6f8;
    cursor: pointer;
  }
`

const ListViewMenu = ({
  listViews,
  onListViewSelection,
  selectedListViewId,
  setSelectedListViewId,
  setIsPopoverOpen,
  objectType,
}: any) => {
  const defaultListView = listViews.find(
    (listView: any) =>
      listView.object_name === objectType && listView.is_default,
  )

  return (
    <ListViewList>
      {(defaultListView ? [] : [{ name: 'Default View', id: 'default' }])
        .concat(
          listViews?.filter((view: any) => view.object_name === objectType),
        )
        .map((listView: any) => (
          <ListViewOption
            key={listView.id}
            selected={
              listView.id === selectedListViewId ||
              (!selectedListViewId && listView.id === 'default')
            }
            onClick={() => {
              if (listView.id === 'default') {
                setSelectedListViewId(undefined)
              } else {
                onListViewSelection(listView.id)
              }
              setIsPopoverOpen(false)
            }}
          >
            {listView.name}
          </ListViewOption>
        ))}
    </ListViewList>
  )
}

const ListViewSelector = (props: any) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const topValue =
    props.listViews.length >= 10 ? 94 : ((props.listViews.length - 1) / 9) * 94

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['left']}
      onClickOutside={() => setIsPopoverOpen(!isPopoverOpen)}
      padding={15}
      transform={{ top: topValue }}
      containerClassName='z-10 relative'
      transformMode='relative'
      content={() => (
        <ListViewMenu setIsPopoverOpen={setIsPopoverOpen} {...props} />
      )}
    >
      <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        <ListViewSelectorContainer className='bg-[#F3F3F3] text-center'>
          <CiViewList size={19} strokeWidth={1} />
          <SelectorText>List Views</SelectorText>
        </ListViewSelectorContainer>
      </div>
    </Popover>
  )
}

export default ListViewSelector
