import {
  Flex,
  Button,
  Checkbox,
  Text,
  Box,
  Portal,
  Center,
  Spinner,
} from '@chakra-ui/react'
import { useState, useEffect, useContext } from 'react'
import { Command } from 'cmdk'
import Api from '../../lib/api'
import { StyledCommandItem } from '.'
import { StateStore } from '../Store'

type Props = {
  page: string
  selectedField: { field_name: string; object_name: string }
  onConfirm: () => void
}

export default function FieldReferences(props: Props) {
  const { page, selectedField, onConfirm } = props

  const { dispatch } = useContext(StateStore)

  const [isLoading, setIsLoading] = useState(true)

  const [fieldReferences, setFieldReferences] = useState<
    Array<{
      groupItem: string
      items: Array<{ api_name: string; label: string }>
    }>
  >([])

  const [selectedType, setSelectedType] = useState('')

  const [selectedFieldReplacements, setSelectedFieldReplacements] = useState<{
    [key: string]: Array<{ api_name: string; label: string }>
  }>({})

  useEffect(() => {
    if (!!selectedField) {
      setIsLoading(true)
      Api.salesforce.customFields
        .fieldReferences({
          fieldName: selectedField?.field_name,
          objectName: selectedField?.object_name,
          isDemoEnv: process.env.NEXT_PUBLIC_APP_ENV === 'staging',
        })
        .then(({ references, type }) => {
          if (Array.isArray(references)) {
            setFieldReferences(references)
            setSelectedType(type)
          }
          setIsLoading(false)
        })
    } else {
      setFieldReferences([])
    }
  }, [selectedField])

  if (page === 'References') {
    return (
      <div
        style={{
          paddingBottom: '10px',
        }}
      >
        {isLoading ? (
          <Center h='200px' w='full'>
            <Spinner />
          </Center>
        ) : (
          <Box maxHeight='600px' overflowY='scroll'>
            {fieldReferences.map(fieldGroup => {
              return (
                <Command.Group
                  key={fieldGroup.groupItem}
                  style={{ paddingTop: '8px', paddingBottom: '8px' }}
                >
                  <Flex
                    w='full'
                    justifyContent='space-between'
                    alignItems='center'
                    pb={2}
                  >
                    <Text
                      fontSize='15px'
                      color='gray.900'
                      pl={5}
                      fontWeight={300}
                    >
                      {fieldGroup.groupItem}
                    </Text>
                    <Flex mr='21.5px'>
                      <Text
                        fontSize='15px'
                        color='gray.900'
                        fontWeight={300}
                        pr={3}
                      >
                        Replace all
                      </Text>
                      <Checkbox
                        colorScheme='purple'
                        isChecked={
                          selectedFieldReplacements[fieldGroup.groupItem]
                            ?.length === fieldGroup.items.length
                        }
                        onChange={e => {
                          if (e.target.checked) {
                            setSelectedFieldReplacements(prev => {
                              return {
                                ...prev,
                                [fieldGroup.groupItem]: fieldGroup.items,
                              }
                            })
                          } else {
                            setSelectedFieldReplacements(prev => {
                              return {
                                ...prev,
                                [fieldGroup.groupItem]: [],
                              }
                            })
                          }
                        }}
                        sx={{
                          '.chakra-checkbox__control': {
                            height: '18px',
                            width: '18px',
                            borderRadius: '3px',
                            borderColor: 'black', // Color of the border
                            _checked: {
                              color: 'white', // Color of the check icon when checked
                              border: 'none',
                              svg: {
                                transform: 'scale(1.3)', // Scale up the checkmark
                              },
                            },
                            _hover: {
                              borderColor: 'black', // Border color on hover
                            },
                            _focus: {
                              boxShadow: 'none', // Remove the focus outline
                            },
                          },
                        }}
                      />
                    </Flex>
                  </Flex>
                  {[
                    ...fieldGroup.items,
                    //   ...fieldGroup.items,
                    //   ...fieldGroup.items,
                  ].map((item, index) => {
                    return (
                      <StyledCommandItem
                        key={`${fieldGroup.groupItem}-item-${index}`}
                      >
                        <Flex
                          direction={'row'}
                          grow={1}
                          width='100%'
                          alignItems={'center'}
                          justifyContent='space-between'
                          paddingX={1}
                          paddingY={2}
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()

                            setSelectedFieldReplacements(prev => {
                              const existingSelected =
                                prev[fieldGroup.groupItem]
                              return {
                                ...prev,
                                [fieldGroup.groupItem]:
                                  existingSelected?.includes(item)
                                    ? existingSelected?.filter(
                                        existingItem => existingItem !== item,
                                      )
                                    : [...(existingSelected ?? []), item],
                              }
                            })
                          }}
                        >
                          <Flex direction={'row'} alignItems='center' gap={4}>
                            <Flex direction={'column'}>
                              <Text fontSize={'14px'} fontWeight={'500'}>
                                {item.label}
                              </Text>
                            </Flex>
                          </Flex>
                          <Checkbox
                            colorScheme='purple'
                            isChecked={selectedFieldReplacements[
                              fieldGroup.groupItem
                            ]?.includes(item)}
                            onChange={e => {
                              if (e.target.checked) {
                                setSelectedFieldReplacements(prev => {
                                  return {
                                    ...prev,
                                    [fieldGroup.groupItem]: [
                                      ...(prev[fieldGroup.groupItem] ?? []),
                                      item,
                                    ],
                                  }
                                })
                              } else {
                                setSelectedFieldReplacements(prev => {
                                  return {
                                    ...prev,
                                    [fieldGroup.groupItem]: prev[
                                      fieldGroup.groupItem
                                    ].filter(
                                      existingItem => existingItem !== item,
                                    ),
                                  }
                                })
                              }
                            }}
                            sx={{
                              '.chakra-checkbox__control': {
                                height: '18px',
                                width: '18px',
                                borderRadius: '3px',
                                borderColor: 'black', // Color of the border
                                _checked: {
                                  color: 'white',
                                  border: 'none', // Color of the check icon when checked
                                  svg: {
                                    transform: 'scale(1.3)', // Scale up the checkmark
                                  },
                                },
                                _hover: {
                                  borderColor: 'black', // Border color on hover
                                },
                                _focus: {
                                  boxShadow: 'none', // Remove the focus outline
                                },
                              },
                            }}
                          />
                        </Flex>
                      </StyledCommandItem>
                    )
                  })}
                </Command.Group>
              )
            })}
          </Box>
        )}

        <Flex justifyContent='flex-end' pr={2}>
          {!isLoading && (
            <Button
              size='sm'
              variant='primaryCTA'
              isDisabled={
                !Object.values(selectedFieldReplacements).some(
                  arr => arr.length > 0,
                )
              }
              {...(!Object.values(selectedFieldReplacements).some(
                arr => arr.length > 0,
              ) && {
                _hover: {
                  cursor: 'not-allowed',
                  opacity: 0.4,
                },
              })}
              onClick={() => {
                dispatch({
                  type: 'TOGGLE_MODAL',
                  payload: {
                    key: 'replaceField',
                    data: {
                      selectedField: selectedField,
                      selectedType: selectedType,
                      references: selectedFieldReplacements,
                    },
                  },
                })
                onConfirm()
              }}
            >
              Replace
            </Button>
          )}
        </Flex>
      </div>
    )
  }

  return <></>
}
