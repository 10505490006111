import React, { useContext, useEffect } from 'react'
import { ContainerProps } from '../types'
import Api from '../lib/api'
import { useRouter } from 'next/router'
import CommandMenu from './CommandMenu'
import { StateStore } from './Store'
import RootModal from './RootModal'
import ModalBackdrop from './ModalBackdrop'

type Props = {} & ContainerProps

const AuthenticatedRoute = (props: Props) => {
  const { push, route } = useRouter()
  const { dispatch } = useContext(StateStore)

  useEffect(() => {
    const getMe = async () => {
      await Api.me()
        .then(user => {
          if (user.errors) {
            dispatch({
              type: 'SET_SOURCE',
              payload: route,
            })

            push('/login')
          }

          dispatch({
            type: 'SET_USER',
            payload: user,
          })
        })
        .catch(e => {
          dispatch({
            type: 'SET_SOURCE',
            payload: route,
          })
          push('/login')
        })
    }

    getMe()
  }, [dispatch, push, route])

  return (
    <>
      <CommandMenu />
      <ModalBackdrop />
      <RootModal />
      {props.children}
    </>
  )
}

export default AuthenticatedRoute
