import React, { ReactNode } from 'react'
import { Flex, Badge, Text } from '@chakra-ui/react'
import { Edit2 } from 'react-feather'

export type CommandRowProps = {
  icon: ReactNode
  label: string
  description?: string
  tags?: Array<string>
  onClick?: (e: unknown) => void
  onEdit?: (e: unknown) => void
}

export const CommandRow = (props: CommandRowProps) => {
  return (
    <Flex
      direction={'row'}
      grow={1}
      width='100%'
      alignItems={'center'}
      justifyContent='space-between'
      paddingX={1}
      paddingY={2}
      onClick={props.onClick}
    >
      <Flex direction={'row'} alignItems='center' gap={4}>
        {props.icon ? props.icon : <></>}
        <Flex direction={'column'}>
          <Text fontSize={'14px'} fontWeight={'500'} className='!text-gray-700'>
            {props.label}
          </Text>
          <Text fontSize={'12px'} color={'#5b5a5a'}>
            {props.description && props.description}
          </Text>
        </Flex>
      </Flex>
      {props.tags ? (
        <Flex direction={'row'} alignItems='center' gap={3}>
          {props.tags.map((tag, key) => (
            <Badge
              variant='objectBadge'
              key={key}
              borderRadius={'50px'}
              border='none'
            >
              {tag}
            </Badge>
          ))}
          {process.env.NEXT_PUBLIC_APP_ENV !== 'staging' && props.onEdit ? (
            <Edit2
              width={18}
              strokeWidth={2}
              color={'#777'}
              {...{
                onClick: props.onEdit,
              }}
            />
          ) : (
            ''
          )}
        </Flex>
      ) : (
        ''
      )}
    </Flex>
  )
}

export default CommandRow
